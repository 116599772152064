import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Queues from './Queues';
import QueueModal from './QueueModal';
import QueuePanel from './QueuePanel';
import Targets from './Targets';
import TargetModal from './TargetModal';
import TargetPanel from './TargetPanel';
import dataAPI from '../../../api/dataAPI';
import { selectUser, fetchQueues, fetchTargets, updateSubscriptions } from '../userSlice';
import styles from '../Profile.module.css';

export default function Subscriptions() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const [entityType, setEntityType] = useState(null);
  const [queueModalIsOpen, setQueueModalIsOpen] = useState(false);
  const [queueModalMode, setQueueModalMode] = useState('create');
  const [selectedQueue, setSelectedQueue] = useState(null);
  const [selectedTarget, setSelectedTarget] = useState(null);
  const [targetModalIsOpen, setTargetModalIsOpen] = useState(false);
  const [targetModalMode, setTargetModalMode] = useState('create');

  const onSubmitQueueModal = () => {
    dispatch(updateSubscriptions(user.usid));
  };

  const onSubmitTargetModal = () => {
    setTargetModalIsOpen(false);
    dispatch(fetchTargets(user.usid));
  };

  const handleQueueSelect = (queue) => {
    setSelectedQueue(queue);
    setEntityType('queue');
    setSelectedTarget(null);
  };

  const handleTargetSelect = (target) => {
    setSelectedTarget(target);
    setEntityType('target');
    setSelectedQueue(null);
  };

  const handleAddQueue = () => {
    setQueueModalMode('create');
    setQueueModalIsOpen(true);
  };

  const handleEditQueue = () => {
    setQueueModalMode('edit');
    setQueueModalIsOpen(true);
  };

  const handleRemoveQueue = async (qid) => {
    await dataAPI.removeQueue(qid);
    dispatch(fetchQueues(user.usid));
  };

  const handleAddTarget = () => {
    setTargetModalMode('create');
    setTargetModalIsOpen(true);
  };

  const handleEditTarget = () => {
    setTargetModalMode('edit');
    setTargetModalIsOpen(true);
  };

  const handleRemoveTarget = async (tarid) => {
    await dataAPI.removeTarget(tarid);
    dispatch(fetchTargets(user.usid));
  };

  return (
    <div className={styles.tabContent}>
      <div className={styles.sections}>
        <div className={styles.leftSection}>
          <div className="height50">
            <Queues onSelect={handleQueueSelect} selected={selectedQueue} onAdd={handleAddQueue} />
          </div>
          <div className="height50">
            <Targets onSelect={handleTargetSelect} selected={selectedTarget} onAdd={handleAddTarget} />
          </div>
        </div>
        <div className={styles.rightSection}>
          {entityType === 'queue' && selectedQueue && (
            <QueuePanel queue={selectedQueue} onEdit={handleEditQueue} onRemove={handleRemoveQueue} />
          )}
          {entityType === 'target' && selectedTarget && (
            <TargetPanel target={selectedTarget} onEdit={handleEditTarget} onRemove={handleRemoveTarget} />
          )}
        </div>
      </div>
      <QueueModal
        open={queueModalIsOpen}
        mode={queueModalMode}
        data={selectedQueue}
        userId={user.usid}
        onClose={() => setQueueModalIsOpen(false)}
        onSubmit={onSubmitQueueModal}
      />
      <TargetModal
        open={targetModalIsOpen}
        mode={targetModalMode}
        data={selectedTarget}
        userId={user.usid}
        onClose={() => setTargetModalIsOpen(false)}
        onSubmit={onSubmitTargetModal}
      />
    </div>
  );
}
