import Box from '@material-ui/core/Box';

export default function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="height100"
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1} className="height100">
          {children}
        </Box>
      )}
    </div>
  );
}
