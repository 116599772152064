import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Scrollbar from '../../../common/Scrollbar/Scrollbar';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Table from '../../../common/Table/Table';
import GetAppIcon from '@material-ui/icons/GetApp';
import PageviewIcon from '@material-ui/icons/Pageview';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RecordsModal from './RecordsModal';
import { selectQueues } from '../userSlice';
import { downloadFile } from '../../../utils';
import Chip from '@material-ui/core/Chip';
import dataAPI from '../../../api/dataAPI';
import styles from '../Profile.module.css';

const ActionButtons = ({ qrid, jrid, onInspect, onDownload, onRemove }) => {
  return (
    <ButtonGroup color="primary" size="small">
      <Button color="primary" onClick={() => onInspect(qrid)}>
        <PageviewIcon />
      </Button>
      <Button color="primary" onClick={() => onDownload(jrid)}>
        <GetAppIcon />
      </Button>
      <Button color="primary" onClick={() => onRemove(qrid)}>
        <DeleteForeverIcon />
      </Button>
    </ButtonGroup>
  );
};

export default function Records() {
  const { t } = useTranslation();
  const queues = useSelector(selectQueues);
  const [records, setRecords] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [checkedRecords, setCheckedRecords] = useState([]);
  const [inspectingQrids, setInspectingQrids] = useState([]);
  const [showInspectingModal, setShowInspectingModal] = useState(false);

  const allowGetTemplate = (value) => {
    if (value) {
      return <Chip className="bg-green" label={t('common:on')} />;
    }
    return <Chip className="bg-red" size="small" label={t('common:off')} />;
  };

  const colorTemplate = ({ color }) => {
    return <Chip className={`bg-${color}`} label={color} />;
  };

  const queuesColumns = [
    { id: 'caption', label: t('common:name'), minWidth: 170 },
    { id: 'allow_get', label: t('record:allowGet'), minWidth: 100, template: allowGetTemplate },
    { key: 'counters.total', id: 'counters', label: t('record:total'), minWidth: 100, format: ({ total }) => total },
    { key: 'counters.held', id: 'counters', label: t('record:put'), minWidth: 100, format: ({ held }) => held },
    { key: 'counters.sent', id: 'counters', label: t('record:sent'), minWidth: 100, format: ({ sent }) => sent },
    { key: 'counters.color', id: 'counters', label: t('record:color'), minWidth: 10, template: colorTemplate },
  ];

  const recordsColumns = [
    { key: 'content.name', id: 'content', label: t('record:name'), minWidth: 170, format: ({ name }) => name },
    { key: 'content.key', id: 'content', label: t('record:key'), minWidth: 100, format: ({ key }) => key },
    { key: 'content.bt', id: 'content', label: t('record:bt'), minWidth: 120, format: ({ bt }) => bt },
    { key: 'content.size', id: 'content', label: t('record:size'), minWidth: 120, format: ({ size }) => size },
    { id: 'put_time', label: t('record:time'), format: (value) => format(value * 1000, 'dd.MM.yyyy') },
    {
      id: 'qrid',
      label: '',
      format: (qrid, row) => (
        <ActionButtons
          qrid={qrid}
          jrid={row.content?.jrid}
          onInspect={handleInspect}
          onDownload={handleBodyDownload}
          onRemove={handleRemove}
        />
      ),
    },
  ];

  const handleSelectRow = (row) => {
    setSelectedRow(row.qid);
  };

  const handleCheck = (qrids) => {
    setCheckedRecords(qrids);
  };

  const handleInspect = (qrid) => {
    qrid ? setInspectingQrids([qrid]) : setInspectingQrids(checkedRecords);
    setShowInspectingModal(true);
  };

  const handleArchiveDownload = async () => {
    const jrids = records.filter((rec) => checkedRecords.includes(rec.qrid)).map((rec) => rec.content?.jrid);
    const response = await dataAPI.downloadRecords(jrids);
    downloadFile('records.zip', response);
  };

  const handleBodyDownload = async (jrid) => {
    const response = await dataAPI.fetchFullRecords([jrid]);
    const msg = response.jrecs.items[0];
    msg && downloadFile(msg.name, `data:application/octet-stream;base64,` + msg.body, 'raw');
  };

  const handleRemove = async (qrid) => {
    await dataAPI.removeRecords(qrid ? [qrid] : checkedRecords);
    fetchRecords();
  };

  const handleClearQueue = async () => {
    await dataAPI.clearQueue(selectedRow);
    fetchRecords();
  };

  const fetchRecords = useCallback(async () => {
    const response = await dataAPI.fetchRecords(selectedRow);
    setRecords(response.queue_recs.items);
  }, [selectedRow]);

  useEffect(() => {
    if (!selectedRow) return;
    fetchRecords();
  }, [selectedRow, fetchRecords]);

  const isDisabledGlobalButtons = !checkedRecords.length;

  return (
    <div className={styles.tabContent}>
      <h3 className={styles.title}>
        {t('profile:records')}
        <ButtonGroup color="primary" className={styles.buttonPanel}>
          <Button
            variant="contained"
            color="primary"
            disabled={isDisabledGlobalButtons}
            onClick={() => handleInspect()}
          >
            <PageviewIcon />
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={isDisabledGlobalButtons}
            onClick={handleArchiveDownload}
          >
            <GetAppIcon />
          </Button>
          <Button variant="contained" color="primary" disabled={isDisabledGlobalButtons} onClick={() => handleRemove()}>
            <DeleteForeverIcon />
          </Button>
        </ButtonGroup>
        <div className={styles.buttonPanel}>
          <Button variant="outlined" color="primary" disabled={!selectedRow} onClick={handleClearQueue}>
            {t('profile:clearQueue')}
          </Button>
        </div>
      </h3>
      <div className={`${styles.sections} ${styles.sectionsReverse}`}>
        <div className={styles.leftSection} style={{ width: '40%', marginRight: '10px' }}>
          <Scrollbar>
            <Table
              columns={queuesColumns}
              rows={queues}
              rowId="qid"
              size="medium"
              onSelect={handleSelectRow}
              selected={selectedRow}
            />
          </Scrollbar>
        </div>
        <div className={styles.rightSection}>
          <Scrollbar>
            <Table
              columns={recordsColumns}
              rows={records}
              rowId="qrid"
              size="small"
              checkbox
              onCheck={handleCheck}
              checked={checkedRecords}
            />
          </Scrollbar>
        </div>
      </div>
      <RecordsModal
        open={showInspectingModal}
        records={records}
        qrids={inspectingQrids}
        onClose={() => setShowInspectingModal(false)}
        onUpdate={fetchRecords}
      />
    </div>
  );
}
