import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import engLocale from './eng';
import rusLocale from './rus';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      eng: engLocale,
      rus: rusLocale,
    },
    lng: localStorage.getItem('lang') || "rus",
    fallbackLng: "rus",

    interpolation: {
      escapeValue: false
    }
  });
