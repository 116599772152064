const eng = {
  menu: {
    main: 'Home',
    editorboard: 'Editor',
    login: 'Login',
  },
  profile: {
    records: 'Data',
    common: 'Common',
    subscriptions: 'Subscriptions',
    target: 'Target',
    targets: 'Targets',
    newTarget: 'New target',
    editTarget: 'Edit target',
    queue: 'Subscription',
    newQueue: 'New subscription',
    editQueue: 'Edit subscription',
    dataset: 'Dataset',
    datasets: 'Datasets',
    subToDataset: 'Subscribe to dataset',
    sendEvery: 'Send every',
    maxFiles: 'Max files',
    compress: 'Compress type',
    fileName: 'File name',
    messageBody: 'Message',
    messageAnswer: 'Answer',
    subject: 'Subject',
    path: 'Catalog path',
    status: 'Status',
    clearQueue: 'Clear queue',
    sendRequest: 'Send request',
    requests: 'Requests',
    newRequest: 'New request',
    schedule: 'Schedule',
    start: 'Start',
    end: 'End',
  },
  searchPage: {
    search: 'Search',
    users: 'Users',
    apps: 'Apps',
    links: 'Links',
    queues: 'Queues',
    signals: 'Signals',
    filter: 'Filter',
    contains: 'Contains',
    intersects: 'Intersects',
    within: 'Within',
    selectionOnly: 'Selection only',
    link: 'links',
    relevance: 'Relevancy',
    changeDate: 'Modified',
    title: 'Title',
    rating: 'Rating',
    popularity: 'Popularity',
    denominatorDesc: 'Low scale first',
    denominatorAsc: 'High scale first',
    registration: 'Registration',
    noSubs: 'No available subscriptions',
    subbed: 'Subscribed',
    requestAccess: 'Request access to datasets',
    unsubscribeFromDataset: 'Unsubscribe from dataset?',
    datasetsIncludes: 'Metadata is included in the following datasets:',
    web: 'Web',
    sru: 'SRU',
    selectExample: 'select example',
    examples: 'Examples',
    startRecord: 'Start record',
    maxRecord: 'Maximum records',
    version: 'Version',
    expand: 'expand',
    collapse: 'collapse',
    reset: 'reset',
    all: 'all',
    metadataRequestAccess: 'Request for access to information',
    datasets: 'Datasets',
    info: 'Information',
    metadata: 'Metadata',
    metadataDownload: 'Download data',
  },
  geoCore: {
    mdActions: 'Available actions',
    types: 'Type of resources',
    topicCats: 'Topics',
    inspireThemesURI: 'Inspre themes',
    keywords: 'Keywords',
    orgNames: 'Org names',
    sourceCatalog: 'Provided by',
    createDateYears: 'Years',
    formats: 'Formats',
    spatialRepresentationTypes: 'Representation types',
    maintenanceAndUpdateFrequencies: 'Update frequencies',
    status: 'Status',
    serviceTypes: 'Services',
    denominators: 'Scale',
    resolutions: 'Resolution',
    isHarvested: 'Harvested',
    categories: 'Categories',
    groupOwners: 'Groups',
    publishedForGroup: 'Visible by',
    standards: 'Metadata standart',
    metadataTypes: 'Type of record',
    isPublishedToAll: 'Public',
    recordOwner: 'Record owner',
    isValid: 'Validation status',
    isValidInspire: 'Validation status (INSPIRE)',
    environment: 'Environment',
    boundaries: 'Boundaries',
    elevation: 'Elevation',
    maps: 'Maps & graphics',
    datasets: 'Datasets',
    interactiveResourcess: 'Interactive resources',
    inlandWaters: 'Inland waters',
    geoscientificInformation: 'Geoscientific Information',
    climatologyMeteorologyAtmosphere: 'Climatology, meteorology, atmosphere',
    resourcesCreateDate: 'Resources create date',
    recordsCreateDate: 'Records create date',
    resourceDateRange: 'resource date',
  },
  user: {
    name: 'Name',
    password: 'Password',
    email: 'E-mail',
    profile: 'Profile',
    logout: 'LogOut',
    host: 'Host',
    port: 'Port',
    user: 'User',
  },
  metadata: {
    back: 'Back to search',
    spatial: 'Spatial extent',
    temporal: 'Temporal extent',
    dateCreate: 'Creation date',
    datePublic: 'Publication date',
    dateRevision: 'Revision date',
    period: 'Period',
    provided: 'Provided by',
    updated: 'Updated',
    downloadsAndLinks: 'Downloads and links',
    aboutResource: 'About this resource',
    categories: 'Categories',
    catCode: 'WMO_CategoryCode',
    gemet: 'GEMET - INSPIRE themes, version 1.0',
    distCode: 'WMO_DistributionScopeCode',
    lang: 'Language',
    resourceId: 'Resource identifier',
    legalConstraints: 'Legal constraints',
    resourceConstraints: 'Resource constraints',
    resourceContact: 'Contact for the resource',
    technicalInfo: 'Technical information',
    updateFrequency: 'Update frequency',
    ref: 'Coordinate reference system',
    format: 'Format',
    lineage: 'Lineage',
    metainfo: 'Metadata information',
    cantact: 'Contact',
    mdLang: 'Metadata language',
    id: 'Identifier',
    representationType: 'Representation types',
    dataAccess: 'Data access',
  },
  common: {
    sort: 'sort',
    rowsPerPage: 'rows per page',
    id: 'Identificator',
    archived: 'Archive date',
    created: 'Create date',
    updated: 'Update date',
    type: 'type',
    name: 'Name',
    email: 'E-mail',
    params: 'Parameters',
    notSelect: 'not select',
    metadata: 'Metadata',
    search: 'Search',
    info: 'Information',
    on: 'on',
    off: 'off',
    def: 'Definition',
    updatedShort: 'Updated',
  },
  notice: {
    noData: 'No data',
    notFound: 'Not found',
    createUser: 'Email has been sent',
    createTarget: 'New target point added',
    createQueue: 'New subscription added',
    update: 'Updated',
    noTargets: "You don't have any targets. Add a new target to be displayed in the list.",
    noQueues: "You don't have any subscriptions. Add a new subscription to be displayed in the list.",
    requestSent: 'Request has been sent',
    fileTooBig: 'file is too big, preview is not possable',
    previewUnavailable: 'There is no preview for this format',
  },
  record: {
    name: 'Name',
    key: 'Key',
    bt: 'Type',
    size: 'Size',
    time: 'Date',
    deep: 'Search deep',
    allowGet: 'Allow',
    total: 'Total',
    put: 'Put',
    sent: 'Sent',
    color: 'Color',
  },
  button: {
    download: 'Download',
    create: 'Create',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    previous: 'Previous',
    next: 'Next',
    open: 'Open',
    accept: 'Accept',
    subscribe: 'Subscribe',
    close: 'Close',
    send: 'Send',
    request: 'Request',
  },
};

export default eng;
