import { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router-dom';
import { patch } from 'jsondiffpatch';

import Paper from '@material-ui/core/Paper';
import { fetchAppData, setAuth } from './appSlice';
import { getUserByTicket, setQueues } from '../pages/profile/userSlice';
import Header from '../features/header/Header';
// import Sidebar from '../features/sidebar/Sidebar';
import Notice from '../features/notice/Notice';
import Main from '../pages/main/Main';
import Metadata from '../pages/metadata/Metadata';
import Profile from '../pages/profile/Profile';
import UserCard from '../pages/profile/UserCard';
import PrivateRoute from '../features/privateRoute/PrivateRoute';

import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarIsOpen: true,
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  componentDidMount() {
    this.props.fetchAppData();
    if (this.hasTicket()) {
      this.setTicket();
      this.props.setAuth(true);
      this.props.getUserByTicket();
      setInterval(this.props.getUserByTicket, 30 * 1000);
      this.websocketInit();
    } else if (sessionStorage.getItem('authTicket')) {
      this.props.getUserByTicket();
      setInterval(this.props.getUserByTicket, 30 * 1000);
      this.websocketInit();
    }
  }

  hasTicket() {
    const url = new URL(window.location.href);
    return Boolean(url.searchParams.get('ticket'));
  }

  setTicket() {
    const url = new URL(window.location.href);
    const ticket = url.searchParams.get('ticket');
    sessionStorage.setItem('authTicket', ticket);
    this.props.history.replace(url);
  }

  handleMenuClick() {
    this.setState({ sidebarIsOpen: !this.state.sidebarIsOpen });
  }

  websocketInit() {
    const ticket = sessionStorage.getItem('authTicket');
    if (!ticket) return;
    const host = this.props.server ? this.props.server.slice(7) : window.location.host;
    const socket = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${host}/queues/ws/queues`);

    socket.onopen = function (e) {
      socket.send(
        JSON.stringify({
          type: 'auth',
          credentials: {
            xticket: ticket,
          },
        })
      );
    };

    socket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      if (response.is_diff) {
        const queues = {
          queues: [...JSON.parse(JSON.stringify(this.props.queues))], //hack to prevent an error on extend object by patch
        };
        const newData = patch(queues, response.data);
        this.props.setQueues({ queues: { items: newData.queues } });
      } else {
        this.props.setQueues({ queues: { items: response.data.queues } });
      }
    };
  }

  render() {
    return (
      <div className="App">
        <Header onClickMenu={this.handleMenuClick} />
        {/* <Sidebar open={this.state.sidebarIsOpen}/> */}
        <PrivateRoute path="/profile">
          <UserCard />
        </PrivateRoute>
        <Paper square className="page-content">
          <Switch>
            <PrivateRoute path="/profile">
              <Profile />
            </PrivateRoute>
            <Route path="/metadata/:id">
              <Metadata />
            </Route>
            <Route path="/">
              <Main />
            </Route>
          </Switch>
        </Paper>
        <Notice />
      </div>
    );
  }
}

const mapStateToProps = ({ app, user }) => {
  return {
    server: app.server,
    queues: user.queues,
  };
};

const mapDispatchToProps = {
  fetchAppData,
  getUserByTicket,
  setAuth,
  setQueues,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
