import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import VerticalTable from '../../../common/VerticalTable/VerticalTable';
import styles from '../Profile.module.css';
import { getDateUTC } from 'utils';

export default function RequestPanel({ request }) {
  const { t } = useTranslation();

  const queueParams = request && [
    {
      [t('common:id')]: request.reqid,
      [t('common:name')]: request.caption,
      [t('common:created')]: format(getDateUTC(request.created * 1000), 'HH:mm dd.MM.yyyy'),
      [t('common:updated')]: format(getDateUTC(request.updated * 1000), 'HH:mm dd.MM.yyyy'),
      [t('profile:messageBody')]: request.data?.text?.msg,
      [t('profile:messageAnswer')]: request.data?.text?.comm,
    },
  ];

  return (
    <div className={styles.infoPanel}>
      <div className={styles.infoPanelHeader}>
        <h3>{t('common:info')}</h3>
        <span>
          {/* <Button
            variant="contained"
            color="primary"
            onClick={onEdit}>
            <EditIcon />
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            className="button-danger"
            onClick={() => onRemove(queue.qid)}>
            <DeleteForeverIcon />
          </Button> */}
        </span>
      </div>
      {request && <VerticalTable data={queueParams} />}
    </div>
  );
}
