import { useState } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Popover from '@material-ui/core/Popover';

export default function Dropdown({ items, onClick, children, value, disableAutoclose }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const lastIndex = items.length - 1;

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickItem = value => () => {
    onClick(value);
    !disableAutoclose && handleClose();
  }

  return (
    <div className="dropdown">
      <div onClick={handleOpen}>{children}</div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <List dense>
          {
            items.map((item, index) => (
              <ListItem
                button
                key={item.id ?? item.value}
                divider={index !== lastIndex}
                onClick={onClickItem(item.value)}
                disabled={item.disabled}
                style={{ minWidth: '150px', background: item.value === value ? '#d3e9ff' : 'initial', color: item.color ? item.color : 'initial' }}
              >
                <ListItemText primary={item.label} />
                {item.icon && item.icon}
              </ListItem>
            ))
          }
        </List>
      </Popover>
    </div>
  )
}
