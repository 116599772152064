import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setNotice } from '../../../app/appSlice';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import dataAPI from '../../../api/dataAPI';

TargetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  data: PropTypes.object,
};

const normalizeTypes = (object) => {
  const formatters = {
    sendEvery: Number,
    maxFiles: Number,
    port: Number,
  };

  const result = { ...object };
  Object.keys(object).forEach((key) => {
    if (formatters[key]) {
      result[key] = formatters[key](object[key]);
    }
  });
  return result;
};

const initalEmailValuesState = {
  type: 'E-MAIL',
  email: '',
  subject: '',
  messageBody: '',
  fileName: '',
  compress: 'ZIP',
  maxFiles: '',
  sendEvery: '',
};

const initialValidationState = {
  name: true,
  email: true,
  subject: true,
  messageBody: true,
  fileName: true,
  compress: true,
  maxFiles: true,
  sendEvery: true,
  path: true,
  host: true,
  port: true,
  user: true,
  pass: true,
};

export default function TargetModal({ open, onClose, onSubmit, mode, data, userId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [values, setValues] = useState({ name: '' });
  const [typeValues, setTypeValues] = useState(initalEmailValuesState);
  const [validationState, setValidationState] = useState(initialValidationState);

  const clearForm = () => {
    setValues({ name: '' });
    setValidationState(initialValidationState);
    setTypeValues(initalEmailValuesState);
  };

  useEffect(() => {
    if (mode === 'create') {
      clearForm();
    } else if (data) {
      setValidationState(initialValidationState);
      setValues({ name: data.name });
      setTypeValues(data.data.proto);
    }
  }, [mode, data]);

  const validateForm = () => {
    const validated = {};
    let errorsCount = 0;
    const allValues = { ...values, ...typeValues };
    const allFields = Object.keys(allValues);
    const fieldsForValidation = Object.keys(validationState).filter((key) => allFields.includes(key));
    fieldsForValidation.forEach((key) => {
      let isValid = typeof allValues[key] === 'number' ? allValues[key] >= 0 : !!allValues[key].length;
      validated[key] = isValid;
      if (!isValid) {
        errorsCount += 1;
      }
    });

    setValidationState(validated);
    return !errorsCount;
  };

  const createHandler = () => {
    if (!validateForm()) return;
    const target = {
      ...values,
      uid: userId,
      data: {
        proto: normalizeTypes(typeValues),
      },
    };
    dataAPI
      .createTarget(target)
      .then(() => {
        onSubmit();
        dispatch(setNotice({ type: 'success', text: t('notice:createTarget'), duration: 2000 }));
        clearForm();
      })
      .catch((e) => dispatch(setNotice({ type: 'error', text: e })));
  };

  const editHandler = () => {
    if (!validateForm()) return;
    const target = {
      ...values,
      data: {
        proto: normalizeTypes(typeValues),
      },
    };
    dataAPI
      .updateTarget(data.tarid, target)
      .then(() => {
        onSubmit();
        dispatch(setNotice({ type: 'success', text: t('notice:update'), duration: 2000 }));
        clearForm();
      })
      .catch((e) => dispatch(setNotice({ type: 'error', text: e })));
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleTypeValueChange = (prop) => (event) => {
    setTypeValues({ ...typeValues, [prop]: event.target.value });
  };

  const handleTypeChange = (e) => {
    const value = e.target.value;
    let data = {};
    if (value === 'E-MAIL') {
      data = {
        type: 'E-MAIL',
        email: '',
        subject: '',
        messageBody: '',
        fileName: '',
        compress: 'ZIP',
        maxFiles: '',
        sendEvery: '',
      };
    }
    if (value === 'FTP') {
      data = {
        type: 'FTP',
        host: '',
        port: '',
        user: '',
        pass: '',
        path: '',
        fileName: '',
        compress: 'ZIP',
        maxFiles: '',
        sendEvery: '',
      };
    }
    setTypeValues(data);
  };

  const title = mode === 'create' ? t('profile:newTarget') : t('profile:editTarget');
  const actionButton =
    mode === 'create' ? (
      <Button onClick={createHandler} className="button-success" size="small">
        {t('button:create')}
      </Button>
    ) : (
      <Button onClick={editHandler} className="button-success" size="small">
        {t('button:save')}
      </Button>
    );

  const compressTypes = (
    <FormControl variant="outlined" size="small" style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}>
      <InputLabel id="compress-select-label">{t('profile:compress')}</InputLabel>
      <Select
        labelId="compress-select-label"
        id="type-select"
        value={typeValues.compress}
        label={t('profile:compress')}
        onChange={handleTypeValueChange('compress')}
      >
        <MenuItem value="ZIP">ZIP</MenuItem>
        <MenuItem value="TAR">TAR</MenuItem>
        <MenuItem value="TGZ">TGZ</MenuItem>
        <MenuItem value="WMO">WMO</MenuItem>
      </Select>
    </FormControl>
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ width: '400px' }}>
        <TextField
          label={t('common:name')}
          value={values.name}
          error={!validationState.name}
          onChange={handleChange('name')}
          margin="dense"
          id="name"
          fullWidth
          required
          variant="outlined"
        />
        <FormControl variant="outlined" size="small" style={{ width: '100%', marginTop: '10px', marginBottom: '15px' }}>
          <InputLabel id="type-select-label">{t('common:type')}</InputLabel>
          <Select
            labelId="type-select-label"
            id="type-select"
            value={typeValues.type}
            label={t('common:type')}
            onChange={handleTypeChange}
          >
            <MenuItem value="E-MAIL">E-mail</MenuItem>
            <MenuItem value="FTP">FTP</MenuItem>
          </Select>
        </FormControl>
        {typeValues.type === 'E-MAIL' && (
          <>
            <TextField
              label={t('common:email')}
              value={typeValues.email}
              error={!validationState.email}
              onChange={handleTypeValueChange('email')}
              margin="dense"
              id="email"
              type="email"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:subject')}
              value={typeValues.subject}
              error={!validationState.subject}
              onChange={handleTypeValueChange('subject')}
              margin="dense"
              id="subject"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:messageBody')}
              value={typeValues.messageBody}
              error={!validationState.messageBody}
              onChange={handleTypeValueChange('messageBody')}
              margin="dense"
              id="message"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:fileName')}
              value={typeValues.fileName}
              error={!validationState.fileName}
              onChange={handleTypeValueChange('fileName')}
              margin="dense"
              id="filename"
              fullWidth
              required
              variant="outlined"
            />
            {compressTypes}
            <TextField
              label={t('profile:maxFiles')}
              value={typeValues.maxFiles}
              error={!validationState.maxFiles}
              onChange={handleTypeValueChange('maxFiles')}
              margin="dense"
              fullWidth
              id="maxfiles"
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:sendEvery')}
              value={typeValues.sendEvery}
              error={!validationState.sendEvery}
              onChange={handleTypeValueChange('sendEvery')}
              margin="dense"
              id="sendevery"
              fullWidth
              required
              variant="outlined"
            />
          </>
        )}
        {typeValues.type === 'FTP' && (
          <>
            <TextField
              label={t('user:host')}
              value={typeValues.host}
              error={!validationState.host}
              onChange={handleTypeValueChange('host')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('user:port')}
              value={typeValues.port}
              error={!validationState.port}
              onChange={handleTypeValueChange('port')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('user:user')}
              value={typeValues.user}
              error={!validationState.user}
              onChange={handleTypeValueChange('user')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('user:password')}
              value={typeValues.pass}
              error={!validationState.pass}
              onChange={handleTypeValueChange('pass')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:path')}
              value={typeValues.path}
              error={!validationState.path}
              onChange={handleTypeValueChange('path')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:fileName')}
              value={typeValues.fileName}
              error={!validationState.fileName}
              onChange={handleTypeValueChange('fileName')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            {compressTypes}
            <TextField
              label={t('profile:maxFiles')}
              value={typeValues.maxFiles}
              error={!validationState.maxFiles}
              onChange={handleTypeValueChange('maxFiles')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
            <TextField
              label={t('profile:sendEvery')}
              value={typeValues.sendEvery}
              error={!validationState.sendEvery}
              onChange={handleTypeValueChange('sendEvery')}
              margin="dense"
              fullWidth
              required
              variant="outlined"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:cancel')}
        </Button>
        {actionButton}
      </DialogActions>
    </Dialog>
  );
}
