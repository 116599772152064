import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { archiveQueue, restoreQueue } from '../userSlice';
import { setNotice } from '../../../app/appSlice';
import dataAPI from '../../../api/dataAPI';

export default function QueueSwitch({ qid }) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isChecked = !useSelector((state) => state.user.queues.find((q) => q.qid === qid)?.archived);

  const handleChange = async (event, isActive) => {
    setIsLoading(true);
    if (isActive) {
      try {
        await dataAPI.restoreQueue(qid);
        await dataAPI.updateQueueRaw(qid, {
          allow_get: true,
          allow_put: true,
        });
      } catch (text) {
        dispatch(setNotice({ type: 'error', text }));
      }
      dispatch(restoreQueue(qid));
    } else {
      try {
        await dataAPI.archiveQueue(qid);
      } catch (text) {
        dispatch(setNotice({ type: 'error', text }));
      }
      dispatch(archiveQueue(qid));
    }
    setIsLoading(false);
  };

  return <Switch color="primary" checked={isChecked} onChange={handleChange} disabled={isLoading} />;
}
