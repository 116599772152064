import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import DoneIcon from '@material-ui/icons/Done';
import Tooltip from '@material-ui/core/Tooltip';
import StorageIcon from '@material-ui/icons/Storage';
import LinkIcon from '@material-ui/icons/Link';
import CloudIcon from '@material-ui/icons/Cloud';
import OpacityIcon from '@material-ui/icons/Opacity';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import SettingsIcon from '@material-ui/icons/Settings';
import PublicIcon from '@material-ui/icons/Public';
import SpaIcon from '@material-ui/icons/Spa';
import FlagIcon from '@material-ui/icons/Flag';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SubjectOutlinedIcon from '@material-ui/icons/SubjectOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import Dropdown from '../../../common/Dropdown/Dropdown';
import { selectHasAuth, setNotice, selectLang, selectGroup } from '../../../app/appSlice';
import {
  selectQueues,
  addSubscription,
  removeSubscription,
  selectSubscriptions,
  selectUser,
} from '../../profile/userSlice';
import dataAPI from '../../../api/dataAPI';
import defaultThumbnail from '../../../images/thumbnail.png';
import logoPlanet from '../../../images/planet.png';
import styles from './Search.module.css';
import UnsubscribeModal from './List/UnsubscribeModal';
import { setDate } from 'date-fns';

const Placeholder = () => (
  <ListItem style={{ alignItems: 'flex-start', minHeight: '140px' }}>
    <div className={styles.itemIcon}>
      <Skeleton variant="circle" width={80} height={80} />
    </div>
    <div className={styles.itemContent}>
      <div className={styles.itemCat}>
        <p />
      </div>
      <Skeleton variant="text" height={30} />
      <Skeleton variant="rect" height={110} />
    </div>
    <ListItemSecondaryAction>
      <Skeleton variant="rect" width={140} height={140} />
    </ListItemSecondaryAction>
  </ListItem>
);

export default function LItem({
  item,
  checked,
  onCheck,
  isLocked,
  requestAccessHandler,
  requestAccessHandlerNew,
  onDownloadData,
  loading,
  ...props
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasAuth = useSelector(selectHasAuth);
  const user = useSelector(selectUser);
  const lang = useSelector(selectLang);
  const group = useSelector(selectGroup);
  const queues = useSelector(selectQueues);
  const subscriptions = useSelector(selectSubscriptions);
  const [unsubscribeModalIsOpen, setUnsubscribeModalIsOpen] = useState(false);
  const [datasets, setDatasets] = useState([]);
  const [unsubscribeQueue, setUnsubscribeQueue] = useState(null);
  const [date, setDate] = useState(null);

  const formatLink = (link, id) => {
    return { id, label: link.name, value: link.url };
  };

  const handleClickLink = (url) => {
    window.open(url, '_blank');
  };

  let linksOptions = [];
  if (item.link) {
    if (Array.isArray(item.link)) {
      linksOptions = item.link.map((link, i) => formatLink(link, i));
    } else {
      linksOptions = [formatLink(item.link)];
    }
  }

  let thumbnail = item.overview?.[0]?.url || defaultThumbnail;
  // if (item.image) {
  //   if (Array.isArray(item.image)) {
  //     thumbnail = item.image[0].split("|")[1];
  //   } else {
  //     thumbnail = item.image.split("|")[1];
  //   }
  // }

  let titleIcon = <FileCopyIcon />;
  let buttonIcon = <LinkIcon />;
  let buttonName = t('searchPage:link');

  if (item.docType === 'dataset') {
    titleIcon = <StorageIcon />;
    buttonIcon = <GetAppIcon />;
    buttonName = t('button:download');
  }

  const topics = !item.cl_topic ? [] : Array.isArray(item.cl_topic) ? item.cl_topic : [item.cl_topic];

  const getTopicIcon = (name) => {
    const topics = {
      climatologyMeteorologyAtmosphere: <CloudIcon color="secondary" />,
      environment: <SpaIcon color="secondary" />,
      boundaries: <MoreHorizIcon color="secondary" />,
      elevation: <FlagIcon color="secondary" />,
      maps: <PublicIcon color="secondary" />,
      datasets: <StorageIcon color="secondary" />,
      interactiveResourcess: <SettingsIcon color="secondary" />,
      inlandWaters: <OpacityIcon color="secondary" />,
      geoscientificInformation: <TrackChangesIcon color="secondary" />,
    };
    return topics[name] || <MoreHorizIcon color="secondary" />;
  };

  const hasSubscription = (queue) => {
    const sub = subscriptions.find((sub) => sub.queue === queue);
    return sub ? sub.metadata.includes(item.uuid) : false;
  };

  let subbedButton = false;

  const queuesOptions = queues.length
    ? queues.map((q) => {
        let color, icon;
        if (hasSubscription(q.qid)) {
          color = '#17bb17';
          icon = <DoneIcon />;
          subbedButton = true;
        }
        return {
          label: q.caption,
          value: q.qid,
          color,
          icon,
        };
      })
    : [{ label: t('searchPage:noSubs'), value: null, disabled: true }];

  const toggleSubscription = async (queueID) => {
    const metadata = item.uuid;
    const queue = queues.find((q) => q.qid === queueID);
    if (hasSubscription(queueID)) {
      const response = await dataAPI.checkMetaInDataset(metadata);
      if (response.datasets.length) {
        setUnsubscribeModalIsOpen(true);
        setDatasets(response.datasets);
        setUnsubscribeQueue(queue);
      } else {
        dispatch(removeSubscription({ queue, metadata }));
      }
    } else {
      dispatch(addSubscription({ queue, metadata }));
    }
  };

  const unsubscribe = () => {
    setUnsubscribeModalIsOpen(false);
    dispatch(
      removeSubscription({
        queue: unsubscribeQueue,
        metadata: item.uuid,
        datasets: datasets.map((ds) => ds.dsid),
      })
    );
  };

  useEffect(() => {
    if (!hasAuth) return;

    const fetchDate = async () => {
      const { jrecs } = await dataAPI.fetchDate(item.uuid);
      const time = jrecs.items?.[0]?.time;
      setDate(time || ' ');
    };
    fetchDate();
  }, []);

  if (loading || item.isPlaceholder) {
    return <Placeholder />;
  }

  const handleUnlock = (e) => {
    e.stopPropagation();
    e.preventDefault();
    requestAccessHandlerNew();
  };

  return (
    <div>
      <ListItem style={{ alignItems: 'flex-start', minHeight: '140px' }} {...props}>
        <div className={styles.itemIcon}>
          {/* <img src={logoPlanet} alt="logo" width="80" /> */}
          <br />
          <Checkbox checked={checked} onChange={onCheck} tabIndex={-1} disableRipple />
        </div>
        <div className={styles.itemContent}>
          <div className={styles.itemCat}>
            {t('geoCore:categories')}
            &nbsp;&nbsp;&nbsp;
            {topics.map((topic) => (
              <Tooltip key={topic.key} title={t(`geoCore:${topic.key}`)} placement="top">
                {getTopicIcon(topic.key)}
              </Tooltip>
            ))}
          </div>
          <Link className={styles.itemTitle} to={`/metadata/${item.uuid}`} data-test-id="metadata-title">
            {titleIcon}
            &nbsp;
            {item.resourceTitleObject?.[`lang${lang}`] || item.resourceTitleObject?.default || item.uuid}
            <span style={{ verticalAlign: 'text-bottom' }}>
              {hasAuth && isLocked ? <LockIcon onClick={handleUnlock} /> : <LockOpenIcon />}
            </span>
          </Link>

          <div>
            <div className={styles.itemDescription}>
              {(item.resourceAbstractObject?.[`lang${lang}`] || item.resourceAbstractObject?.default || '').slice(
                0,
                240
              )}
            </div>
            <div className={styles.itemFooter}>
              <div>
                {item.link && (
                  <Dropdown items={linksOptions} onClick={handleClickLink}>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={buttonIcon}
                      endIcon={<ArrowDropDownIcon />}
                    >
                      {linksOptions.length}
                      &nbsp;
                      {buttonName}
                    </Button>
                  </Dropdown>
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onDownloadData({ src: item.sourceCatalogue, id: item.uuid })}
                >
                  Загрузить данные
                </Button>
              </div>
              {item.status && <Chip label={item.status_text} variant="outlined" color="primary" />}
              <div>
                {hasAuth && (
                  <span>
                    {!date ? (
                      <RotateRightIcon className="rotating" />
                    ) : date === ' ' ? (
                      date
                    ) : (
                      format(new Date(date * 1000), 'dd.MM.yyyy HH:mm:ss')
                    )}
                  </span>
                )}
                {hasAuth && (
                  <>
                    {/* <Dropdown items={queuesOptions} onClick={toggleSubscription} disableAutoclose>
                      <Chip
                        style={{ marginRight: '10px' }}
                        icon={subbedButton ? <DoneIcon /> : null}
                        label={subbedButton ? t('searchPage:subbed') : t('button:subscribe')}
                        clickable
                        color="primary"
                        variant={subbedButton ? 'default' : 'outlined'}
                      />
                    </Dropdown> */}
                    {!isLocked && (
                      <Tooltip title={t(`searchPage:requestAccess`)} placement="top">
                        <IconButton variant="outlined" color="secondary" onClick={requestAccessHandler}>
                          <NoteAddIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Link to={`/data/${item.uuid}`}>
                      <IconButton variant="outlined" color="secondary">
                        <SubjectOutlinedIcon />
                      </IconButton>
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <ListItemSecondaryAction>
          <img src={thumbnail} width="140" height="auto" alt="thumbnail" />
        </ListItemSecondaryAction>
      </ListItem>
      <UnsubscribeModal
        open={unsubscribeModalIsOpen}
        onClose={() => setUnsubscribeModalIsOpen(false)}
        onAccept={unsubscribe}
        datasets={datasets}
      />
    </div>
  );
}
