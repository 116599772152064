let store;

export function injectStoreToAPI(_store) {
  store = _store;
}

function request(method, url, body) {
  const host = store.getState().app.server;
  return fetch(`${host}/acl/api/v1/${url}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'X-Ticket': sessionStorage.getItem('authTicket'),
      'X-Service': window.location.origin,
    },
    body: body ? JSON.stringify(body) : null,
  })
    .then((res) => {
      // if (res.status === 401) {
      //   const appHost = encodeURIComponent(window.location.href);
      //   const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
      //   sessionStorage.removeItem('authTicket');
      //   window.location = loginUrl;
      //   return;
      // }
      try {
        return res
          .clone()
          .json()
          .catch(() => res.text());
      } catch (e) {
        console.log(e);
      }
    })
    .then(
      (result) => {
        return result.response;
      },
      (error) => {
        console.log(error);
        throw error;
      }
    );
}

function getUser() {
  return request('GET', 'sessions/info');
}

function fetchUserDatasets(usid) {
  return request('GET', `users/${usid}`);
}

function createUser(data) {
  return request('POST', 'users/new', data);
}

function logout() {
  return request('GET', 'cas/logout');
}

function createRequest(data) {
  return request('POST', 'requests/new', data);
}

function findGroupByName(name) {
  return request('POST', 'groups/list', { searchStr: name, search: ['name'] });
}

function fetchGroups(data) {
  return request('POST', 'groups/list', data);
}

function fetchCompanies() {
  let url = window.location.href;
  url = /localhost|heroku/g.test(url) ? 'https://rzd.modext.ru:8088' : url;
  return request('GET', `cas/info?url=${url}`);
}

function fetchSignals(data) {
  return request('POST', 'requests/list', data);
}

function createSignal(data) {
  return request('POST', 'requests/new', data);
}

function archiveSignal(id) {
  return request('POST', `requests/archive?reqid=${id}`);
}

function removeSignal(id) {
  return request('POST', `requests/rem?reqid=${id}`);
}

const casAPI = {
  getUser,
  createUser,
  fetchUserDatasets,
  logout,
  createRequest,
  findGroupByName,
  fetchCompanies,
  fetchGroups,
  fetchSignals,
  createSignal,
  archiveSignal,
  removeSignal,
};

export default casAPI;
