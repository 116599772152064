
class Coordinates {
  constructor(coords = []) {
    this.coords = Array.isArray(coords) ? [...coords] : []
  }

  getCoords() {
    return this.coords
  }

  swap() {
    this.coords = this.coords.map(pair => [...pair].reverse())
    return this
  }

  getBounds() {
    if (this.coords.length < 4) return null
    return [this.coords[0], this.coords[2]]
  }
}

export default Coordinates

