import styles from './Input.module.css'

export default function Input({ label, labelWidth, inputWidth, ...props }) {
  const labelStyles = labelWidth ? { width: labelWidth } : null;
  const inputStyles = inputWidth ? { width: inputWidth } : null;
  const input = <input className={styles.input} {...props} style={inputStyles} />

  if (!label) return input;

  return (
    <label className={styles.wrapper}>
      <span className={styles.label} style={labelStyles}>{label}</span>
      {input}
    </label>
  );
}
