import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  metaList: [],
  searchParams: {
    search: '',
    example: '',
    start: 1,
    max: 5,
    version: 1.1,
  }
};

export const metaSlice = createSlice({
  name: 'sru',
  initialState,
  reducers: {
    setMetaList: (state, action) => {
      state.metaList = action.payload;
    },
    updateSearchParams: (state, action) => {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload
      }
      if (action.payload.example) {
        state.searchParams.search = action.payload.example;
      }
    }
  },
});

export const {
  setMetaList,
  updateSearchParams } = metaSlice.actions;

export const selectMetaList = (state) => state.sru.metaList;
export const selectSearchParams = (state) => state.sru.searchParams;

export default metaSlice.reducer;
