import { useSelector, useDispatch } from 'react-redux';
import Params from './Params.js';
import {
  selectSearchParams,
  updateSearchParams,
} from './sruSlice';
import geoAPI from '../../../api/geoAPI';

export default function SRU() {
  const params = useSelector(selectSearchParams);
  const dispatch = useDispatch();

  const startSearch = async (e, search) => {
    const response = await geoAPI.startSruSearch(params);
    if (!response) return;
    let blob = new Blob([response], { type: 'text/xml' });
    let url = URL.createObjectURL(blob);
    window.open(url, 'data', "height=660,width=800");
    URL.revokeObjectURL(url);
  }

  const handleParamsChange = key => ({ target: { value } }) => {
    dispatch(updateSearchParams({ [key]: value }));
  }

  return (
    <div>
      <Params
        value={params}
        onStart={startSearch}
        onChange={handleParamsChange}
      />
    </div>
  );
}
