import { useEffect } from 'react';
import uniqid from 'uniqid';

export function makeConsistent(options) {
  return (obj = {}) => {
    const consistentObj = {};

    Object.keys(obj).forEach((_key) => {
      let key = _key;
      if (options.removeAtSign) {
        key = _key.replace(/@/gi, '');
      }
      if (options.array && options.array.includes(key)) {
        consistentObj[key] = Array.isArray(obj[_key]) ? obj[_key] : [obj[_key]];
      } else {
        consistentObj[key] = obj[_key];
      }
    });

    if (options.array) {
      options.array.forEach((key) => {
        if (!consistentObj[key]) {
          consistentObj[key] = [];
        }
      });
    }

    return consistentObj;
  };
}

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

// export const debounce = (callback, time) => {
//   let interval;
//   return (...args) => {
//     clearTimeout(interval);
//     interval = setTimeout(() => {
//       interval = null;
//       callback(...args);
//     }, time);
//   };
// };

export function downloadFile(filename, body, type) {
  const data = type === 'raw' ? body : URL.createObjectURL(body);
  var element = document.createElement('a');

  element.setAttribute('href', data);
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function insertId(data) {
  if (Array.isArray(data)) {
    return data.map((item) => ({ ...item, id: uniqid() }));
  } else if (typeof data === 'object') {
    return { ...data, id: uniqid() };
  }
  return data;
}

export function extractId(data) {
  if (Array.isArray(data)) {
    data.forEach((item) => delete item.id);
  } else if (typeof data === 'object') {
    delete data.id;
  }
  return data;
}

export function getDateUTC(value) {
  return new Date(new Date(value).toISOString().split('.')[0]);
}
