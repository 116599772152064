import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import noData from '../../images/no_data.png';
import notFound from '../../images/not_found.png';
import styles from './Placeholder.module.css';

Placeholder.propTypes = {
  type: PropTypes.oneOf(['nodata', 'notfound']),
  text: PropTypes.string,
}

export default function Placeholder({ type, text, mini }) {
  const { t } = useTranslation();

  let src = ''
  let title = ''
  if (type === 'nodata') {
    src = noData;
    title = t('notice:noData');
  }
  if (type === 'notfound') {
    src = notFound;
    title = t('notice:notFound');
  }

  return (
    <div className={`${styles.placeholder} ${mini ? styles.mini : ''}`}>
      <img className={styles.image} src={src} alt={type} />
      <div className={styles.title}>
        {title}
      </div>
      <div className={styles.text}>
        {text}
      </div>
    </div>
  );
}
