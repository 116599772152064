import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {},
  targets: [],
  requests: [],
  queues: [],
  subscriptions: [],
  datasets: [],
  userDatasets: [],
};

export const getUserByTicket = (payload) => ({ type: 'user/getUserByTicket', payload });
export const fetchTargets = (payload) => ({ type: 'user/fetchTargets', payload });
export const fetchRequests = (payload) => ({ type: 'user/fetchRequests', payload });
export const removeRequest = (payload) => ({ type: 'user/removeRequest', payload });
export const fetchQueues = (payload) => ({ type: 'user/fetchQueues', payload });
export const addSubscription = (payload) => ({ type: 'user/addSubscription', payload });
export const removeSubscription = (payload) => ({ type: 'user/removeSubscription', payload });
export const updateSubscriptions = (payload) => ({ type: 'user/updateSubscriptions', payload });

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfo: (state, { payload }) => {
      state.info = payload.session.user;
    },
    setTargets: (state, { payload }) => {
      state.targets = payload.targets.items;
    },
    setRequests: (state, { payload }) => {
      state.requests = payload.items;
    },
    setQueues: (state, { payload }) => {
      state.queues = payload.queues.items;
    },
    archiveQueue: (state, { payload }) => {
      const queue = state.queues.find((queue) => queue.qid === payload);
      queue.archived = true;
    },
    restoreQueue: (state, { payload }) => {
      const queue = state.queues.find((queue) => queue.qid === payload);
      queue.archived = false;
    },
    setDatasets: (state, { payload }) => {
      state.datasets = payload;
    },
    setUserDatasets: (state, { payload = [] }) => {
      state.userDatasets = [...payload];
    },
    subscribe: (state, { payload }) => {
      const sub = state.subscriptions.find((sub) => sub.queue === payload.queue.qid);
      if (!sub) {
        state.subscriptions.push({ queue: payload.queue.qid, metadata: [payload.metadata] });
      } else {
        const filtered = state.subscriptions.filter((sub) => sub.queue !== payload.queue.qid);
        const updatedSub = { ...sub, metadata: [...sub.metadata, payload.metadata] };
        state.subscriptions = [...filtered, updatedSub];
      }
      const index = state.queues.findIndex((queue) => queue.qid === payload.queue.qid);
      state.queues = [...state.queues.slice(0, index), payload.queue, ...state.queues.slice(index + 1)];
    },
    unsubscribe: (state, { payload }) => {
      const sub = state.subscriptions.find((sub) => sub.queue === payload.queue.qid);
      const updatedSub = { ...sub, metadata: sub.metadata.filter((id) => id !== payload.metadata) };
      const filtered = state.subscriptions.filter((sub) => sub.queue !== payload.queue.qid);
      state.subscriptions = [...filtered, updatedSub];
      const index = state.queues.findIndex((queue) => queue.qid === payload.queue.qid);
      state.queues = [...state.queues.slice(0, index), payload.queue, ...state.queues.slice(index + 1)];
    },
    parseSubscriptionsFromQueues: (state) => {
      const queues = state.queues;
      const datasets = state.datasets;
      const subscriptions = [];
      queues.forEach((queue) => {
        if (!queue.filters) return;
        const incFilters = queue.filters.filter((filter) => filter.sig === 'inc');
        incFilters.forEach((incF) => {
          if (!incF.filter) return;
          const subs = incF.filter.filter((filter) => filter.field === 'urn');
          const sets = incF.filter.filter((filter) => filter.field === 'dataset').map((filter) => filter.text);
          const arraysOfSubs = datasets.filter((ds) => sets.includes(ds.dsid)).map((ds) => ds.data.products);
          if (!subs.length && !arraysOfSubs.length) return;
          subscriptions.push({
            queue: queue.qid,
            metadata: [...subs.map((sub) => sub.text), ...arraysOfSubs.reduce((acc, item) => [...acc, ...item], [])],
          });
        });
        state.subscriptions = subscriptions;
      });
    },
  },
});

export const { parseSubscriptionsFromQueues, archiveQueue, restoreQueue, setQueues } = userSlice.actions;

export const selectUser = (state) => state.user.info;
export const selectTargets = (state) => state.user.targets;
export const selectRequests = (state) => state.user.requests;
export const selectQueues = (state) => state.user.queues;
export const selectDatasets = (state) => state.user.datasets;
export const selectUserDatasets = (state) => state.user.userDatasets;
export const selectSubscriptions = (state) => state.user.subscriptions;

export default userSlice.reducer;
