import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Scrollbar from '../../../common/Scrollbar/Scrollbar';
// import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Table from '../../../common/Table/Table';
import LoadingOverlay from '../../../common/LoadingOverlay/LoadingOverlay';
import { downloadFile } from '../../../utils';
import dataAPI from '../../../api/dataAPI';
import geoAPI from '../../../api/geoAPI';

export default function DataModal({ open, id, title, onClose }) {
  const { t } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [fetchedTitle, setFetchedTitle] = useState('');
  const [startAt, setStartAt] = useState('24');
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  const columns = [
    { id: 'name', label: t('record:name'), minWidth: 200, maxWidth: 300 },
    { id: 'time', label: t('record:time'), format: (value) => value && format(value * 1000, 'dd.MM.yyyy') },
    { id: 'size', label: t('record:size'), minWidth: 100 },
  ];

  const fetchRecords = async () => {
    setIsLoading(true);
    const gap = Number(startAt) * 60 * 60 * 1000;
    const date = Math.floor((new Date().getTime() - gap) / 1000);
    const response = await dataAPI.fetchMetainfo(id, date);
    response && setMessages(response.jrecs.items);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!open) return;
    setSelectedMessage({});
    setMessages([]);
    fetchRecords();
  }, [open, id, startAt]);

  useEffect(() => {
    if (!title) {
      async function fetchMetadata() {
        const response = await geoAPI.fetchMetadataById(id);
        setFetchedTitle(response.metadata[0]?.resourceTitleObject?.default || '');
      }
      fetchMetadata();
    }
  }, [title]);

  const handleChangeRange = ({ target }) => {
    setStartAt(target.value);
  };

  // const handleRemove = async () => {
  //     dataAPI.remove(selectedMessage.jrid)
  //     const restMessages = messages.filter(msg => msg.jrid !== selectedMessage.jrid)
  //     setMessages(restMessages)
  //     setSelectedMessage({})
  // }

  const handleDownload = async () => {
    if (selectedMessage.bt === 'file') {
      const link = selectedMessage?.file_link?.link_id;
      const { file_link, body } = await dataAPI.downloadFile(link, true);
      downloadFile(selectedMessage.name, `data:${file_link.type};base64,` + body, 'raw');
    } else {
      downloadFile(selectedMessage.name, `data:application/octet-stream;base64,` + selectedMessage.body, 'raw');
    }
  };

  const handleSelect = async (msg) => {
    if (msg.bt === 'file') {
      setIsLoading(true);
      const link = msg.file_link?.link_id;
      const { file_link } = await dataAPI.downloadFile(link);
      if (/image/.test(file_link.type)) {
        if (file_link.size <= 512000) {
          const { body } = await dataAPI.downloadFile(link, true);
          setSelectedMessage({ ...msg, ...file_link, imageBody: body });
        } else if (/image/.test(file_link.type)) {
          setSelectedMessage({ ...msg, ...file_link, imageError: t('notice:fileTooBig') });
        }
      } else if (/.txt$/.test(msg.name)) {
        const { body } = await dataAPI.downloadFile(link, true);
        setSelectedMessage({ ...msg, ...file_link, textBody: body });
      } else {
        setSelectedMessage({ ...msg, ...file_link, imageError: t('notice:previewUnavailable') });
      }
      setIsLoading(false);
    } else {
      setSelectedMessage(msg);
    }
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose} className="nowidth-limit">
        <DialogTitle>{title || fetchedTitle}</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div style={{ width: '900px', height: '480px' }}>
              <LoadingOverlay />
            </div>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <div style={{ width: '100%', height: '100%' }}>
                  <Scrollbar>
                    <Table
                      columns={columns}
                      rows={messages}
                      rowId="jrid"
                      onSelect={handleSelect}
                      selected={selectedMessage.jrid}
                    />
                  </Scrollbar>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div style={{ minHeight: '400px', height: '100%', paddingRight: '10px' }}>
                  {selectedMessage.jrid && (
                    <>
                      {selectedMessage.bt !== 'file' ? (
                        <textarea
                          value={selectedMessage.body ? atob(selectedMessage.body) : ''}
                          readOnly
                          style={{
                            height: '100%',
                            width: '80%',
                            resize: 'none',
                            marginRight: '10px',
                            marginTop: '10px',
                          }}
                        />
                      ) : (
                        <div style={{ float: 'left' }}>
                          <p>
                            <b>name:</b> {selectedMessage.name}
                          </p>
                          <p>
                            <b>size:</b> {selectedMessage.size}
                          </p>
                          <p>
                            <b>type:</b> {selectedMessage.type}
                          </p>
                          {selectedMessage.imageBody && (
                            <img
                              onClick={() => setShowPreview(true)}
                              width="300"
                              style={{ cursor: 'pointer' }}
                              src={'data:image/jpeg;base64,' + selectedMessage.imageBody}
                            />
                          )}
                          {selectedMessage.textBody && <p>{selectedMessage.textBody}</p>}
                          {selectedMessage.imageError}
                        </div>
                      )}
                      <div style={{ float: 'right', marginRight: '10px' }}>
                        <Button variant="contained" color="primary" onClick={handleDownload}>
                          <GetAppIcon />
                        </Button>
                        <br />
                        <br />
                        {/* <Button
                      variant="contained"
                      className="button-danger"
                      onClick={handleRemove}
                    >
                      <DeleteForeverIcon />
                    </Button> */}
                      </div>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <div style={{ position: 'absolute', left: '20px' }}>
            <span>{t('record:deep')}&nbsp;&nbsp;&nbsp;</span>
            <Select value={startAt} onChange={handleChangeRange}>
              <MenuItem value="6">6</MenuItem>
              <MenuItem value="12">12</MenuItem>
              <MenuItem value="18">18</MenuItem>
              <MenuItem value="24">24</MenuItem>
            </Select>
          </div>
          <Button variant="contained" onClick={onClose} color="primary" size="small">
            {t('button:close')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showPreview} onClose={() => setShowPreview(false)}>
        <DialogContent>
          <img src={'data:image/jpeg;base64,' + selectedMessage.imageBody} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
