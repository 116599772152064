import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Dropdown from '../../common/Dropdown/Dropdown';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import MenuIcon from '@material-ui/icons/Menu';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectServer,
  selectLang,
  changeLang,
  selectCompany,
  changeCompany,
  selectCompanies,
  selectHasAuth,
  setAuth,
  selectDomainLock,
} from '../../app/appSlice';
import { selectUser } from '../../pages/profile/userSlice';
import casAPI from '../../api/casAPI';
import styles from './Header.module.css';
import flagRU from '../../images/icons/flag-rus.svg';
import flagEN from '../../images/icons/flag-eng.svg';

const langs = [
  { value: 'rus', label: 'Русский' },
  { value: 'eng', label: 'English' },
];

export default function Header({ onClickMenu }) {
  const { i18n, t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const companies = useSelector(selectCompanies);
  const lang = useSelector(selectLang);
  const server = useSelector(selectServer);
  const hasAuth = useSelector(selectHasAuth);
  const hasDomainLock = useSelector(selectDomainLock);
  const user = useSelector(selectUser);
  const [title, setTitle] = useState('');

  const handleLangChange = (value) => {
    dispatch(changeLang(value));
    i18n.changeLanguage(value);
  };

  useEffect(() => {
    const title = lang === 'rus' ? 'Поиск по метаданным' : 'Поиск по метаданным';
    document.title = title;
    setTitle(title);
  }, [lang]);

  const flag = lang === 'rus' ? flagRU : flagEN;

  const profileMenu = [
    { label: t('user:profile'), value: 'profile' },
    { label: t('user:logout'), value: 'logout' },
  ];

  const handleMenuClick = (action) => {
    if (action === 'profile') {
      history.push('/profile');
      return;
    }
    if (action === 'logout') {
      casAPI.logout(sessionStorage.getItem('authTicket'));
      sessionStorage.removeItem('authTicket');
      dispatch(setAuth(false));
      history.replace('/');
    }
  };

  const appHost = encodeURIComponent(window.location.href);
  const loginUrl = `${server}/acl/api/v1/cas/login?service=${appHost}`;

  return (
    <AppBar position="fixed">
      <Toolbar>
        {/* <IconButton onClick={onClickMenu} edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton> */}
        <Typography variant="h6" className={styles.title}>
          {/* <Link to="/" className="styleless-link">{company.name}</Link> */}

          {/* {!hasDomainLock &&
            <Select
              style={{ marginLeft: '30px' }}
              className={styles.select}
              onChange={({ target }) => dispatch(changeCompany(target.value))}
              value={company.id}
            >
              {
                companies.map(company => (<MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>))
              }
            </Select>
          } */}
          <Link to="/">
            <div className={styles.headerLogo}>
              <div>{title}</div>
            </div>
          </Link>
        </Typography>
        <div className={styles.rightBlock}>
          {hasAuth ? (
            <>
              <Dropdown items={profileMenu} onClick={handleMenuClick}>
                <IconButton aria-haspopup="true" color="inherit" style={{ marginRight: '10px' }}>
                  <AccountCircle fontSize="large" />
                </IconButton>
              </Dropdown>
              <Link to="/profile" className={`styleless-link ${styles.login}`}>
                {user.name}
              </Link>
            </>
          ) : (
            <a href={loginUrl} className={`styleless-link ${styles.login}`}>
              {t('menu:login')}
            </a>
          )}
          <Dropdown items={langs} value={lang} onClick={handleLangChange}>
            <IconButton>
              <img src={flag} className={styles.langButton} alt="language" />
            </IconButton>
          </Dropdown>
        </div>
      </Toolbar>
    </AppBar>
  );
}
