import { Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { selectHasAuth } from '../../app/appSlice';

PrivateRoute.propTypes = {
  children: PropTypes.element.isRequired,
}

export default function PrivateRoute({ children, ...rest }) {
  const hasAuth = useSelector(selectHasAuth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        hasAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
