import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import styles from './styles.css';

export default function Row({ timeFrom, timeTo, id, onRemove }) {
  const { t } = useTranslation();

  return (
    <div className="schedule-range">
      <TextField className="schedule-range__from" label={t('profile:start')} type="time" defaultValue={timeFrom} />
      <TextField className="schedule-range__to" label={t('profile:end')} type="time" defaultValue={timeTo} />
      <div>
        <IconButton size="small" onClick={() => onRemove(id)} aria-label="delete" color="primary">
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
      </div>
    </div>
  );
}
