import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import Placeholder from '../../../features/placeholder/Placeholder';
import { selectTargets } from '../userSlice';
import styles from '../Profile.module.css';

export default function Targets({ onSelect, selected, onAdd }) {
  const { t } = useTranslation();
  const targets = useSelector(selectTargets);

  const columns = [
    { id: 'name', label: t('common:name'), minWidth: 170 },
    { id: 'data', label: t('common:type'), minWidth: 170, format: (data) => data.proto.type },
    {
      id: 'data', key: 'proto', label: t('common:params'), minWidth: 170,
      format: ({ proto }) => Object.keys(proto).map(key => <span key={key}><b>{key}: </b>{proto[key]}&nbsp;</span>),
    },
  ];

  useEffect(() => {
    if (!selected) return
    const target = targets.find(target => target.tarid === selected.tarid)
    onSelect(target)
  }, [targets])

  return (
    <div className={styles.tabContent}>
      <br />
      <br />
      <h3 className={styles.title}>
        <Button
          className={`${styles.headerButton} button-success`}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAdd}>
          {t('button:add')}
        </Button>
        {t('profile:targets')}
      </h3>
      {targets && !!targets.length &&
        <Table
          columns={columns}
          rows={targets}
          rowId="tarid"
          extraClass="archived"
          extraCondition={(row) => row.archived}
          onSelect={onSelect}
          selected={selected && selected.tarid}
          stickyHeader
        />
      }
      {targets && !targets.length &&
        <Placeholder mini type="nodata" text={t('notice:noTargets')} />
      }
    </div>
  );
}
