const rus = {
  menu: {
    main: 'Главная',
    editorboard: 'Редактор',
    login: 'Войти',
  },
  profile: {
    records: 'Данные',
    common: 'Общая',
    subscriptions: 'Подписки',
    target: 'Точка доставки',
    targets: 'Точки доставки',
    newTarget: 'Новая точка доставки',
    editTarget: 'Редактирование точки доставки',
    queue: 'Подписка',
    newQueue: 'Новая подписка',
    editQueue: 'Редактирование подписки',
    dataset: 'Набор данных',
    datasets: 'Наборы данных',
    subToDataset: 'Подписаться на набор данных',
    sendEvery: 'Отправка каждые N минут',
    maxFiles: 'Количество сообщений в файле',
    compress: 'Вид сжатия',
    fileName: 'Имя файла',
    messageBody: 'Сообщение',
    messageAnswer: 'Ответ',
    subject: 'Тема',
    path: 'Каталог записи',
    status: 'Статус',
    host: 'Хост',
    port: 'Порт',
    user: 'Пользователь',
    pass: 'Пароль',
    type: 'Тип',
    clearQueue: 'Очистить очередь',
    sendRequest: 'Отправить запрос',
    requests: 'Запросы',
    newRequest: 'Новый запрос',
    schedule: 'Расписание',
    start: 'Начало',
    end: 'Конец',
  },
  searchPage: {
    search: 'Поиск',
    users: 'Пользователи',
    apps: 'Приложения',
    links: 'Линки',
    queues: 'Очередь',
    signals: 'Сигналы',
    filter: 'Фильтр',
    contains: 'Содержит',
    intersects: 'Пересекает',
    within: 'Внутри',
    selectionOnly: 'Только выбранные',
    link: 'Ссылки',
    climatologyMeteorologyAtmosphere: 'Климатология и метеорология',
    relevance: 'Актуальность',
    changeDate: 'Изменение',
    title: 'Наименование',
    rating: 'Рейтинг',
    popularity: 'Популярность',
    denominatorDesc: 'В малом масштабе',
    denominatorAsc: 'Крупным планом',
    registration: 'Регистрация',
    noSubs: 'Нет доступных подписок',
    subbed: 'Подписан',
    requestAccess: 'Запросить доступ к наборам данных',
    unsubscribeFromDataset: 'Отписаться от всего набора данных?',
    datasetsIncludes: 'Метаданные входят в набор данных:',
    web: 'Web',
    sru: 'SRU',
    selectExample: 'Выберите пример',
    examples: 'Примеры',
    startRecord: 'Начальная запись',
    maxRecord: 'Количество записей',
    version: 'Версия',
    expand: 'развернуть',
    collapse: 'свернуть',
    reset: 'сбросить',
    all: 'все',
    metadataRequestAccess: 'Запрос на доступ к информации',
    datasets: 'Наборы данных',
    info: 'Информация',
    metadata: 'Метаданные',
    metadataDownload: 'Загрузка данных',
  },
  geoCore: {
    mdActions: 'Доступные действия',
    types: 'Тип ресурсов',
    topicCats: 'Темы',
    inspireThemesURI: 'Темы INSPIRE',
    keywords: 'Ключевые слова',
    orgNames: 'Контакт для ресурса',
    sourceCatalog: 'Предоставлено',
    createDateYears: 'Годы',
    formats: 'Форматы',
    spatialRepresentationTypes: 'Типы представлений',
    maintenanceAndUpdateFrequencies: 'Частота обновления',
    status: 'Статус',
    serviceTypes: 'Виды услуг',
    denominators: 'Масштаб',
    resolutions: 'Разрешение',
    isHarvested: 'Заготовлено',
    categories: 'Категории',
    groupOwners: 'Группы',
    publishedForGroup: 'Видимый',
    standards: 'Стандарт метаданных',
    metadataTypes: 'Тип записи',
    isPublishedToAll: 'Public',
    recordOwner: 'Record owner',
    isValid: 'Validation status',
    isValidInspire: 'Validation status (INSPIRE)',
    environment: 'Окружающая среда',
    boundaries: 'Границы',
    elevation: 'Рельеф',
    maps: 'Карты и графика',
    datasets: 'Наборы данных',
    interactiveResourcess: 'Интерактивные ресурсы',
    inlandWaters: 'Поверхностные воды',
    geoscientificInformation: 'Данные наук о Земле',
    climatologyMeteorologyAtmosphere: 'Климатология и метеорология',
    resourcesCreateDate: 'Дата создания ресурсов',
    recordsCreateDate: 'Дата создания записей',
    resourceDateRange: 'Период данных',
  },
  user: {
    name: 'Имя',
    password: 'Пароль',
    email: 'E-mail',
    profile: 'Профиль',
    logout: 'Выйти',
    host: 'Хост',
    port: 'Порт',
    user: 'Пользователь',
  },
  metadata: {
    back: 'Обратно к поиску',
    spatial: 'Пространственная протяженность',
    temporal: 'Временная степень',
    dateCreate: 'Дата создания',
    datePublic: 'Дата публикации',
    dateRevision: 'Дата проверки',
    period: 'Период',
    provided: 'Предоставлено',
    updated: 'Обновлено',
    downloadsAndLinks: 'Скачивание и ссылки',
    aboutResource: 'Об этом ресурсе',
    categories: 'Категории',
    catCode: 'WMO_CategoryCode',
    gemet: 'GEMET - INSPIRE themes, version 1.0',
    distCode: 'WMO_DistributionScopeCode',
    lang: 'Язык',
    resourceId: 'Идентификатор ресурса',
    legalConstraints: 'Правовые ограничения',
    resourceConstraints: 'Ограничение в ресурсах',
    resourceContact: 'Контакт для ресурса',
    technicalInfo: 'Техническая информация',
    updateFrequency: 'Частота обновления',
    ref: 'Система координат',
    format: 'Формат',
    lineage: 'Lineage',
    metainfo: 'Информация о метаданных',
    contact: 'Контакт',
    mdLang: 'Язык метаданных',
    id: 'Идентификатор',
    representationType: 'Типы представлений',
    dataAccess: 'Доступ к данным',
  },
  common: {
    sort: 'сортировка по',
    rowsPerPage: 'элементов на странице',
    id: 'Идентификатор',
    archived: 'Дата архивации',
    created: 'Дата создания',
    updated: 'Дата обновления',
    updatedShort: 'Обновлено',
    type: 'Тип',
    name: 'Название',
    email: 'E-mail',
    params: 'Параметры',
    notSelect: 'не выбрано',
    metadata: 'Метаданные',
    search: 'Поиск',
    info: 'Информация',
    on: 'вкл',
    off: 'выкл',
    def: 'Описание',
  },
  notice: {
    noData: 'Нет данных',
    notFound: 'Данных не найдено',
    createUser: 'Письмо для подтверждения регистрации отправлено на почту',
    createTarget: 'Добавлена новая точка доставки',
    createQueue: 'Добавлена новая подписка',
    update: 'Данные успешно обновлены',
    noTargets: 'У вас нет точек доставки. Добавьте новую точку доставки для отображения в списке.',
    noQueues: 'У вас нет активных подписок. Добавьте новую подписку для отображения в списке.',
    requestSent: 'Запрос отправлен',
    fileTooBig: 'файл слишком большой, предпросмотр недоступен',
    previewUnavailable: 'Для данного формата предпросмотр недоступен',
  },
  record: {
    name: 'Наименование',
    key: 'Ключ',
    bt: 'Тип',
    size: 'Размер',
    time: 'Дата',
    deep: 'Глубина поиска',
    allowGet: 'Выдача',
    total: 'Всего',
    put: 'Задержано',
    sent: 'Передано',
    color: 'Цвет',
  },
  button: {
    download: 'Скачать',
    create: 'Создать',
    add: 'Добавить',
    save: 'Сохранить',
    cancel: 'Отмена',
    previous: 'Предыдущие',
    next: 'Далее',
    open: 'Открыть',
    accept: 'Принять',
    subscribe: 'Подписаться',
    close: 'Закрыть',
    send: 'Отправить',
    request: 'Запросить',
  },
};

export default rus;
