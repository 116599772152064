import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';
import { insertId } from 'utils';

import Range from './Range';

export default function Schedule({ values }) {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState([]);

  const handleRemoveRange = (id) => {
    setRanges(ranges.filter((range) => range.id !== id));
  };

  const handleAddRange = () => {
    setRanges((ranges) => [...ranges, insertId({ timeFrom: '00:00', timeTo: '00:00' })]);
  };

  useEffect(() => {
    setRanges(values ?? []);
  }, [values]);

  return (
    <div className="schedule">
      {ranges.map((range) => (
        <Range key={range.id} {...range} onRemove={handleRemoveRange} />
      ))}
      <Button size="small" variant="outlined" color="primary" onClick={handleAddRange}>
        {t('button:add')}
      </Button>
    </div>
  );
}
