import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { useSelector } from 'react-redux';
import { selectCompanies } from '../../app/appSlice';
import { selectUser } from './userSlice';
import styles from './Profile.module.css';
import src from '../../images/profile-bg.jpeg';

export default function UserCard () {
  const user = useSelector(selectUser);
  const companies = useSelector(selectCompanies);
  const company = companies.find( company => company.id === user.cmpid) || {};

  return (
    <Card className={styles.userCard} square >
        <CardMedia
          className={styles.userCardMedia}
          image={src}
        />
        <CardContent>
          <Avatar className={styles.avatar}/>
          <Typography gutterBottom variant="h5" component="h2">
            {user.name}
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon>
              <LocationCityIcon />
              </ListItemIcon>
              <ListItemText
                primary={company.name || '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
              <MailOutlineIcon />
              </ListItemIcon>
              <ListItemText
                primary={user.email || '-'}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
              <PhoneIphoneIcon />
              </ListItemIcon>
              <ListItemText
                primary={user.phone || '-'}
              />
            </ListItem>
          </List>
        </CardContent>
    </Card>
  );
}
