import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects';
import appReducer from './appSlice';
import userReducer from '../pages/profile/userSlice';
import metaReducer from '../pages/main/search/metaSlice';
import sruReducer from '../pages/main/sru/sruSlice';
import appSagas from './sagas'
import userSagas from '../pages/profile/sagas';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    meta: metaReducer,
    sru: sruReducer,
  },
  middleware: [sagaMiddleware],
});

function* rootSaga() {
  yield all([
    ...appSagas,
    ...userSagas,
  ])
}

sagaMiddleware.run(rootSaga);
