import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, Route } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import List from '@material-ui/core/List';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import TablePagination from '@material-ui/core/TablePagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { selectLang } from '../../../app/appSlice';
import {
  selectMetaList,
  setMetaList,
  selectFiltersList,
  setFiltersList,
  selectActiveFilters,
  setActiveFilters,
  resetActiveFilters,
  selectPagination,
  updatePagination,
  selectSearchParams,
  updateSearchParams,
  resetSearchParams,
  replaceState,
} from './metaSlice';
import Panel from '../../../common/Panel/Panel';
import DataModal from './DataModal';
import Params from './Params';
import Filters from './Filters';
import Fab from '@material-ui/core/Fab';
import PublicIcon from '@material-ui/icons/Public';
import Map from './Map';
import ListItem from './ListItem';
import Dropdown from '../../../common/Dropdown/Dropdown';
import Placeholder from '../../../features/placeholder/Placeholder';
import geoAPI from '../../../api/geoAPI';
import { makeConsistent } from '../../../utils';
import Coordinates from '../../../utils/Coordinates';
import styles from './Search.module.css';
import { selectUserDatasets } from 'pages/profile/userSlice';
import RequestAccessModal from './RequestAccessModal';
import RequestAccessModalNew from './RequestAccessModalNew';
import DownloadMetadata from './modals/DownloadMetadata';
import Showcase from '../showcase/Showcase';

import dataAPI from 'api/dataAPI';

export default function Search({ showcase, onCloseShowcase }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const lang = useSelector(selectLang);
  const userDatasets = useSelector(selectUserDatasets);
  const metadata = useSelector(selectMetaList);
  const filters = useSelector(selectFiltersList);
  const resetCounter = useSelector((state) => state.meta.resetCounter);
  const activeFilters = useSelector(selectActiveFilters);
  const pagination = useSelector(selectPagination);
  const params = useSelector(selectSearchParams);
  const [mapParams, setMapParams] = useState({
    geometry: '',
    relation: '',
  });
  const [metainfo, setMetainfo] = useState([]);
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [geobox, setGeobox] = useState(null);
  const [checked, setChecked] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [accessableMetadata, setAccessableMetadata] = useState([]);
  const initialRender = useRef(true);
  const [requestAccessId, setRequestAccessId] = useState(null);
  const [requestAccessData, setRequestAccessData] = useState({});
  const [showRequestAccessModal, setShowRequestAccessModal] = useState(false);
  const [showRequestAccessModalNew, setShowRequestAccessModalNew] = useState(false);
  const [selectedMetadataForDownload, setSelectedMetadataForDownload] = useState(null);

  const showcaseMode = showcase && !window.sessionStorage.getItem('searchParams');

  useEffect(() => {
    if (resetCounter) {
      startSearch();
    }
  }, [resetCounter]);

  useEffect(() => {
    if (!mapParams.geometry) return;
    startSearch();
  }, [mapParams.geometry, mapParams.relation]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      if (!metadata.length) {
        dispatch(setMetaList(Array(10).fill({ isPlaceholder: true })));
        startSearch();
      }
    } else {
      startSearch();
    }
  }, [pagination.rowsPerPage, pagination.page, lang, activeFilters, params.sortBy]);

  const handleParamsChange = (key) => (value) => {
    dispatch(updateSearchParams({ [key]: value }));
  };

  const handleFiltersChange = (value) => {
    dispatch(setActiveFilters(value));
  };

  const handleFiltersReset = (value) => {
    dispatch(resetActiveFilters());
  };

  const saveSearchParams = (search) => {
    const obj = {
      activeFilters,
      params: {
        ...params,
        search: search ?? params.search,
      },
      pagination,
    };
    window.sessionStorage.setItem('searchParams', JSON.stringify(obj));
  };
  useEffect(() => {
    const params = window.sessionStorage.getItem('searchParams');
    if (params) {
      dispatch(replaceState(JSON.parse(params)));
    }
  }, []);

  const startSearch = async (e, search) => {
    console.log({ search });
    setLoading(true);
    const uuids = checked.length ? { _uuid: checked.map((value) => ({ value })) } : {};
    try {
      const composedParams = {
        ...params,
        ...mapParams,
        ...uuids,
        filters: activeFilters,
        search: search ?? params.search,
        from: (search ? 0 : pagination.page) * pagination.rowsPerPage,
        to: (search ? 0 : pagination.page) * pagination.rowsPerPage + pagination.rowsPerPage,
        lang: lang.slice(0, 2).toUpperCase(),
      };
      const response = await geoAPI.fetchMetadata(composedParams);
      const metadata = makeConsistent({ array: ['metadata'] })(response).metadata;
      dispatch(setMetaList(metadata));
      !showcaseMode && saveSearchParams(search);
      if (response.summary) {
        dispatch(setFiltersList(response.summary.dimension));
        dispatch(updatePagination({ count: response.summary.count }));
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
    setLoading(false);
  };

  const startSearchFromInput = (...args) => {
    showcaseMode && onCloseShowcase();
    startSearch(...args);
  };

  const onMapChange = (key) => (value) => {
    setMapParams((params) => ({
      ...params,
      [key]: value,
    }));
  };

  const onEnterRow = (geobox) => () => {
    if (!geobox) return;
    const coords = new Coordinates(geobox.coordinates[0]);
    setGeobox(coords.swap().getBounds());
  };
  const onLeaveRow = () => {
    setGeobox(null);
  };

  const handleRequestAccessNew = (item) => {
    const title = item.resourceTitleObject?.[`lang${lang}`] || item.resourceTitleObject?.default;
    const license = Array.isArray(item.licenseObject)
      ? item.licenseObject
      : item.licenseObject
      ? [item.licenseObject]
      : [];
    setRequestAccessData({ id: item.uuid, title, license });
    setShowRequestAccessModalNew(true);
  };

  const handleRequestAccess = (id) => {
    setRequestAccessId(id);
    setShowRequestAccessModal(true);
  };

  const handleCheck = (uuid) => () => {
    const currentIndex = checked.indexOf(uuid);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(uuid);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCheckAll = () => {
    if (checkedAll) {
      setChecked([]);
      setCheckedAll(false);
    } else {
      const uuids = metadata.map((item) => item.uuid);
      setChecked(uuids);
      setCheckedAll(true);
    }
  };

  const handleAction = (action) => {
    if (action === 'selection') {
      startSearch();
    }
  };

  const checkAccessToMetadata = async (dsid, uid) => {
    if (!uid.length || !dsid.length) return;
    const params = {
      uid,
      dsid,
    };
    const { datasets } = await dataAPI.checkDataset(params);
    const accessableMetadata = new Set(datasets.reduce((acc, set) => [...acc, ...set.body.uid], []));
    setAccessableMetadata((state) => [...state, ...accessableMetadata]);
  };

  useEffect(() => {
    const isPrivate = ({ licenseObject = [] }) => {
      const data = Array.isArray(licenseObject) ? licenseObject : [licenseObject];
      const isPublic = (str = '') => /(wmoessential|nolimitation)/.test(str.replace(/\s/g, '').toLowerCase());
      return !data.some((obj) => isPublic(obj.default));
    };
    setAccessableMetadata(metadata.filter((o) => !isPrivate(o)).map(({ uuid }) => uuid));
    const metadataIds = metadata
      .filter(isPrivate)
      .map(({ uuid }) => uuid)
      .filter(Boolean);
    checkAccessToMetadata(userDatasets, metadataIds);
  }, [userDatasets, metadata]);

  const geoBoxes = metadata
    .filter((item) => item.geom)
    .map((item) => {
      const coords = new Coordinates(item.geom.coordinates[0]);
      return coords.swap().getBounds();
    });

  const ActionItems = [
    { label: 'Export (ZIP)', value: 'zip' },
    { label: 'Export (PDF)', value: 'pdf' },
    { label: 'Export (CSV)', value: 'csv' },
    { label: t('searchPage:selectionOnly'), value: 'selection' },
  ];

  const listHeader = (
    <div className={styles.listHeader}>
      <div style={{ width: '150px' }}>
        <Badge classes={{ badge: styles.badge }} badgeContent={checked.length} color="primary">
          <Checkbox checked={checkedAll} onChange={handleCheckAll} tabIndex={-1} disableRipple />
        </Badge>
        {Boolean(checked.length) && (
          <Dropdown items={ActionItems} onClick={handleAction}>
            <Button variant="outlined" color="primary" size="small" style={{ marginLeft: '20px' }}>
              <DescriptionIcon />
            </Button>
          </Dropdown>
        )}
      </div>

      <div style={{ width: '320px' }}>
        <span>{t('common:sort')}&nbsp;</span>
        <Select value={params.sortBy} onChange={(e) => handleParamsChange('sortBy')(e.target.value)}>
          <MenuItem value="relevance">{t('searchPage:relevance')}</MenuItem>
          <MenuItem value="changeDate">{t('searchPage:changeDate')}</MenuItem>
          <MenuItem value="title">{t('searchPage:title')}</MenuItem>
          {/* <MenuItem value='rating'>{t('searchPage:rating')}</MenuItem>
          <MenuItem value='popularity'>{t('searchPage:popularity')}</MenuItem>
          <MenuItem value='denominatorDesc'>{t('searchPage:denominatorDesc')}</MenuItem>
          <MenuItem value='denominatorAsc'>{t('searchPage:denominatorAsc')}</MenuItem> */}
        </Select>
      </div>
      <TablePagination
        style={{ width: '600px' }}
        component="div"
        labelRowsPerPage={t('common:rowsPerPage')}
        onChangePage={(e, page) => dispatch(updatePagination({ page }))}
        onChangeRowsPerPage={(e) => dispatch(updatePagination({ page: 0, rowsPerPage: e.target.value }))}
        {...pagination}
      />
    </div>
  );

  return (
    <div className="height100">
      <Row className="justify-content-md-center">
        <Col xs={10} style={{ height: '83px' }}>
          <Params
            value={params}
            onStart={startSearchFromInput}
            onChange={handleParamsChange}
            onReset={() => dispatch(resetSearchParams())}
          />
        </Col>
      </Row>
      {showcaseMode ? (
        <Showcase
          onClose={onCloseShowcase}
          onChange={handleFiltersChange}
          filters={filters}
          onReset={handleFiltersReset}
        />
      ) : (
        <Row style={{ height: 'calc(100% - 83px)' }}>
          <Col md={3} style={{ height: '100%' }}>
            <Filters
              loading={loading}
              items={filters}
              active={activeFilters}
              onChange={handleFiltersChange}
              onReset={handleFiltersReset}
            />
          </Col>
          <Col md={9} style={{ height: '100%' }}>
            {Boolean(metadata.length) && (
              <Panel header={listHeader}>
                <List dense={false}>
                  {metadata.map((item, i) => (
                    <ListItem
                      key={item.uuid || i}
                      item={item}
                      checked={checked.includes(item.uuid)}
                      onCheck={handleCheck(item.uuid)}
                      onMouseEnter={onEnterRow(item.geom)}
                      onMouseLeave={onLeaveRow}
                      requestAccessHandlerNew={() => handleRequestAccessNew(item)}
                      requestAccessHandler={() => handleRequestAccess(item.uuid)}
                      loading={loading}
                      isLocked={!accessableMetadata.includes(item.uuid)}
                      onDownloadData={(a) => setSelectedMetadataForDownload(a)}
                    />
                  ))}
                </List>
              </Panel>
            )}
            {!metadata.length && <Placeholder type="notfound" />}
          </Col>
        </Row>
      )}
      <Route
        path="/data/:id"
        render={(props) => <DataModal open={true} id={props.match.params.id} onClose={() => history.replace(`/`)} />}
      />
      <RequestAccessModal
        open={showRequestAccessModal}
        id={requestAccessId}
        onClose={() => setShowRequestAccessModal(false)}
      />
      <RequestAccessModalNew
        open={showRequestAccessModalNew}
        data={requestAccessData}
        onClose={() => setShowRequestAccessModalNew(false)}
      />
      <DownloadMetadata
        open={!!selectedMetadataForDownload}
        data={selectedMetadataForDownload}
        onClose={() => setSelectedMetadataForDownload(null)}
      />
      {showMap ? (
        <Map data={geoBoxes} selected={geobox} onChange={onMapChange} onClose={() => setShowMap(false)} />
      ) : (
        <Fab className={styles.mapButton} color="primary" onClick={() => setShowMap(true)}>
          <PublicIcon />
        </Fab>
      )}
    </div>
  );
}
