import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, Link, useHistory, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Dropdown from '../../common/Dropdown/Dropdown';
import Scrollbar from '../../common/Scrollbar/Scrollbar';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SettingsIcon from '@material-ui/icons/Settings';
import TodayIcon from '@material-ui/icons/Today';
import StorageIcon from '@material-ui/icons/Storage';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Placeholder from '../../features/placeholder/Placeholder';
import Map from '../main/search/Map';
import LinkIcon from '@material-ui/icons/Link';
import logoPlanet from '../../images/planet.png';
import DataModal from '../main/search/DataModal';
import { selectLang, setNotice } from '../../app/appSlice';
import { selectMetaList, setMetaList, updateSearchParams, setActiveFilters } from '../main/search/metaSlice';
import { makeConsistent, downloadFile } from '../../utils';
import Coordinates from '../../utils/Coordinates';
import geoAPI from '../../api/geoAPI';
import dataAPI from '../../api/dataAPI';
import styles from './Metadata.module.scss';

const ActionItems = [
  // { label: 'Export (ZIP)', value: 'zip' },
  // { label: 'Export (PDF)', value: 'xsl-view?output=pdf' },
  { label: 'Export (XML)', value: 'xml' },
];

const formatLink = (link, id = '1') => {
  return { id, title: link.name, desc: link.description, href: link.url };
};

const getLang = (langs = []) => {
  const lang = {
    eng: 'English',
    rus: 'Русский',
  };
  return typeof langs === 'string' ? lang[langs] : langs.map((name) => lang[name] || name).join(' ');
};

const initialDataState = {
  allKeywords: [],
  cl_topic: [],
  resourceTitleObject: {},
  resourceAbstractObject: {},
  resourceIdentifier: [],
  cl_accessConstraints: [],
  cl_spatialRepresentationType: [],
  contactForResource: [],
  contact: [],
  crsDetails: [],
  MD_LegalConstraintsOtherConstraintsObject: [],
  cl_maintenanceAndUpdateFrequency: [{ text: '' }],
  geom: { coordinates: [] },
};

export default function Metadata() {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const lang = useSelector(selectLang);
  const [rowData, setRowData] = useState(initialDataState);
  const [hasData, setHasData] = useState(null);
  const metaList = useSelector(selectMetaList);
  const [hasDataForModal, setHasDataForModal] = useState(false);

  const metaIDs = metaList.map((meta) => meta.uuid);
  const currentMetaIndex = rowData.uuid ? metaIDs.indexOf(rowData.uuid) : -1;

  const handleClickLink = (url) => () => {
    window.open(url, '_blank');
  };

  const data = makeConsistent({
    array: [
      'link',
      'topicCat',
      'categories',
      'legalConstraints',
      'resourceConstraints',
      'responsibleParty',
      'mdLanguage',
      'docLocale',
      'tempExtentPeriod',
    ],
  })(rowData);
  const linksOptions = data.link.map((link, i) => formatLink(link, i));
  // const categories = [...data.categories, data.topicCat];
  const titleIcon = data.type === 'dataset' ? <StorageIcon /> : <FileCopyIcon />;

  useEffect(() => {
    async function fetchMetadata() {
      const response = await geoAPI.fetchMetadataById(id);
      if (response.metadata.length) {
        setHasData(true);
        setRowData({ ...initialDataState, ...response.metadata[0] });
      } else {
        setHasData(false);
      }
    }
    async function fetchData() {
      const gap = 24 * 60 * 60 * 1000;
      const date = Math.floor((new Date().getTime() - gap) / 1000);
      const response = await dataAPI.fetchMetainfo(id, date);
      if (response.jrecs.items.length) {
        setHasDataForModal(true);
      } else {
        setHasDataForModal(false);
      }
    }
    fetchMetadata();
    fetchData();
  }, [id]);

  const handleDownload = async (type) => {
    const formatName = (name = '') => name.replace(/:/g, '_');
    try {
      const body = await geoAPI.fetchXML(data.uuid);
      downloadFile(`${formatName(data.uuid)}.xml`, body);
    } catch (e) {
      dispatch(setNotice({ type: 'error', text: e.message }));
    }
  };

  const handlePrevMetadata = () => {
    const prevMetaID = metaIDs[currentMetaIndex - 1];
    prevMetaID && history.push(`/metadata/${prevMetaID}`);
  };

  const handleNextMetadata = () => {
    const nextMetaID = metaIDs[currentMetaIndex + 1];
    nextMetaID && history.push(`/metadata/${nextMetaID}`);
  };

  const handleSearch = (param, value) => () => {
    dispatch(updateSearchParams({ [param]: [{ value, label: value }] }));
    dispatch(setMetaList([]));
    history.push('/');
  };

  const handleSearchByTopic = (cat) => () => {
    dispatch(setActiveFilters({ topicCat: cat }));
    dispatch(setMetaList([]));
    history.push('/');
  };

  const coords = new Coordinates(data.geom.coordinates[0]);
  const geobox = coords.swap().getBounds();

  useEffect(() => {}, []);

  if (hasData === null) {
    return null;
  } else if (!hasData) {
    return <Placeholder type="notfound" />;
  }

  return (
    <Scrollbar>
      <div className={styles.root}>
        <div className={styles.header}>
          <div>
            <Link to="/">
              <Button size="small" variant="outlined" color="primary" startIcon={<SearchIcon />}>
                {t('metadata:back')}
              </Button>
            </Link>
            <ButtonGroup size="small" color="primary">
              <Button startIcon={<ArrowBackIosIcon />} disabled={currentMetaIndex < 1} onClick={handlePrevMetadata}>
                {t('button:previous')}
              </Button>
              <Button
                endIcon={<ArrowForwardIosIcon />}
                disabled={currentMetaIndex >= metaIDs.length - 1}
                onClick={handleNextMetadata}
              >
                {t('button:next')}
              </Button>
            </ButtonGroup>
          </div>
          <Dropdown items={ActionItems} onClick={handleDownload}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<GetAppIcon />}
              endIcon={<ArrowDropDownIcon />}
            >
              {t('button:download')}
            </Button>
          </Dropdown>
        </div>

        <div className={styles.content}>
          <div className={styles.leftSection}>
            <h4 className={styles.overflowBreak}>
              {titleIcon}
              &nbsp;
              {data.resourceTitleObject[`lang${lang}`] || data.resourceTitleObject.default}
            </h4>
            <p className={styles.overflowBreak}>
              {data.resourceAbstractObject[`lang${lang}`] || data.resourceAbstractObject.default}
            </p>
            <h5>{t('metadata:downloadsAndLinks')}</h5>
            {linksOptions.map((link, i) => (
              <div className={styles.linkPanel} key={link.id + i}>
                <div className={styles.linkIcon}>
                  <LinkIcon fontSize="large" color="primary" />
                </div>
                <div className={styles.linkDescription}>
                  <h6>
                    {link.title} <a href={link.href}>{link.href}</a>
                  </h6>
                  <p>{link.desc}</p>
                </div>
                <Button variant="contained" color="primary" size="small" onClick={handleClickLink(link.href)}>
                  {t('button:open')}
                </Button>
              </div>
            ))}
            {hasDataForModal && (
              <div className={styles.linkPanel}>
                <Link to={`${data.uuid}/data`}>{t('metadata:dataAccess')}</Link>
              </div>
            )}
            <br />
            <br />
            <h5>{t('metadata:aboutResource')}</h5>
            <div className={styles.paramsTable}>
              <div className={styles.paramsRow}>
                <div>{t('metadata:categories')}</div>
                <div>
                  {data.cl_topic.map(({ key: cat }) => (
                    <Chip
                      key={cat}
                      className={styles.chipCat}
                      label={t(`geoCore:${cat}`)}
                      variant="outlined"
                      color="primary"
                      onClick={handleSearch('cat', cat)}
                    />
                  ))}
                  {/* {
                    data.topicCat.map(cat =>
                      <Chip
                        key={cat}
                        className={styles.chipCat}
                        label={t(`geoCore:${cat}`)}
                        variant="outlined"
                        color="primary"
                        onClick={handleSearchByTopic(cat)}
                      />)
                  } */}
                </div>
              </div>
              {Object.keys(data.allKeywords).map((group) => (
                <div key={group} className={styles.paramsRow}>
                  <div>{data.allKeywords[group].title || group}</div>
                  <div className={styles.keywordsWrapper}>
                    {data.allKeywords[group].keywords.map((keyword) => (
                      <span key={keyword.default} className={styles.keyWord}>
                        <span className={styles.cropText}>{keyword[`lang${lang}`] || keyword.default}</span>
                        <SearchIcon
                          color="primary"
                          className={styles.searchButton}
                          fontSize="small"
                          onClick={handleSearch('keyword', keyword[`lang${lang}`] || keyword.default)}
                        />
                      </span>
                    ))}
                  </div>
                </div>
              ))}
              <div className={styles.paramsRow}>
                <div>{t('metadata:lang')}</div>
                <div>{getLang(data.resourceLanguage)}</div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:resourceId')}</div>
                <div>
                  {data.resourceIdentifier.map((ident, i) => (
                    <p key={i}>{ident.code}</p>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:legalConstraints')}</div>
                <div>
                  {data.MD_LegalConstraintsOtherConstraintsObject.map((item) => (
                    <p key={item.default}>{item.default}</p>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:resourceConstraints')}</div>
                <div>
                  {data.cl_accessConstraints.map((item) => (
                    <p key={item.default}>{item.default}</p>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:resourceContact')}</div>
                <div>
                  {data.contactForResource.map((contact, i) => (
                    <div key={i}>
                      <p>
                        <b>{contact.organisation}</b>
                      </p>
                      {contact.address && <p>{contact.address}</p>}
                      <p>
                        &nbsp;&nbsp;<b>point of contact:</b> {contact.individual}
                      </p>
                      <p>
                        &nbsp;&nbsp;<b>email</b>: {contact.email}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <br />
            <br />
            <h5>{t('metadata:technicalInfo')}</h5>
            <div className={styles.paramsTable}>
              <div className={styles.paramsRow}>
                <div>{t('metadata:updateFrequency')}</div>
                <div>
                  {data.cl_maintenanceAndUpdateFrequency.map((freq) => (
                    <span key={freq.default} className={styles.keyWord}>
                      {freq[`lang${lang}`] || freq.default}
                    </span>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:representationType')}</div>
                <div>
                  {data.cl_spatialRepresentationType.map((type) => (
                    <span key={type}>{type[`lang${lang}`] || type.default}</span>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:ref')}</div>
                <div>
                  {data.crsDetails.map((crs) => (
                    <p key={crs.code}>
                      {crs.code} : {crs.codeSpace}
                    </p>
                  ))}
                </div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:format')}</div>
                <div>{data.format && <Chip label={data.format} color="secondary" />}</div>
              </div>
              <div className={styles.paramsRow}>
                <div>{t('metadata:lineage')}</div>
                <div>{data.lineage}</div>
              </div>
            </div>

            <br />
            <br />
            <h5>
              {t('metadata:metainfo')}
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => handleDownload('xml')}
                startIcon={<GetAppIcon />}
              >
                {t('button:download')}
              </Button>
            </h5>
            <div className={styles.paramsRow}>
              <div>{t('metadata:contact')}</div>
              <div>
                {data.contact.map((contact, i) => (
                  <div key={i}>
                    <p>
                      <b>{contact.organisation}</b>
                    </p>
                    {contact.address && <p>{contact.address}</p>}
                    <p>
                      &nbsp;&nbsp;<b>point of contact:</b> {contact.individual}
                    </p>
                    <p>
                      &nbsp;&nbsp;<b>email</b>: {contact.email}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.paramsRow}>
              <div>{t('metadata:mdLang')}</div>
              <div>{getLang(data.mainLanguage)}</div>
            </div>
            <div className={styles.paramsRow}>
              <div>{t('metadata:id')}</div>
              <div>{data.metadataIdentifier}</div>
            </div>
          </div>

          <div className={styles.rightSection}>
            <div className={styles.subHeader}>
              <LocationOnIcon />
              {t('metadata:spatial')}
            </div>
            <Map
              data={[]}
              selected={geobox}
              onChange={() => () => {}}
              onClose={() => {}}
              fitBounds={geobox}
              noControls
              zoomControl
            />
            <p>{data.geoDesc}</p>

            <div className={styles.subHeader}>
              <ScheduleIcon />
              {t('metadata:temporal')}
            </div>
            <p>
              <b>{t('metadata:dateCreate')}</b>
              <span>{data.dateStamp && format(new Date(data.dateStamp), 'dd.MM.yyyy')}</span>
            </p>
            <p>
              <b>{t('metadata:datePublic')}</b>
              <span>{data.indexingDate && format(new Date(data.indexingDate), 'dd.MM.yyyy')}</span>
            </p>
            <p>
              <b>{t('metadata:period')}</b>
              <span>
                {`${
                  data.resourceTemporalExtentDetails?.[0]?.start.date
                    ? format(new Date(data.resourceTemporalExtentDetails?.[0]?.start.date), 'dd.MM.yyyy')
                    : '-'
                } - ${
                  data.resourceTemporalExtentDetails?.[0]?.end.date
                    ? format(new Date(data.resourceTemporalExtentDetails?.[0]?.end.date), 'dd.MM.yyyy')
                    : '-'
                }`}
              </span>
            </p>
            <br />
            <span className={styles.subHeader}>
              <SettingsIcon />
              {t('metadata:provided')}
            </span>
            <p>
              <span>{data.sourceCatalogue}</span>
            </p>
            <br />
            {/* <p>{data.changeDate && format(new Date(data.changeDate), 'dd.MM.yyyy')}</p> */}
          </div>
        </div>
        <Route path="/metadata/:id/data">
          <DataModal
            open={true}
            id={data.uuid}
            title={data.resourceTitleObject?.default}
            onClose={() => history.replace(`/metadata/${data.uuid}`)}
          />
        </Route>
      </div>
    </Scrollbar>
  );
}
