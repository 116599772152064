import { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../common/TabPanel/TabPanel';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Search from './search/Search';
import SRU from './sru/SRU';

export default function Main() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [isShowcaseActive, setIsShowcaseActive] = useState(true);

  return (
    <div className="page page-padding">
      <Paper square style={{ marginBottom: '40px' }}>
        <Tabs
          style={{ display: 'none' }}
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setActiveTab(v)}
        >
          <Tab label={t('searchPage:web')} />
          <Tab label={t('searchPage:sru')} />
        </Tabs>
      </Paper>
      <TabPanel value={activeTab} index={0} className="full-height-tab">
        <Search showcase={isShowcaseActive} onCloseShowcase={() => setIsShowcaseActive(false)} />
      </TabPanel>
      <TabPanel value={activeTab} index={1} className="full-height-tab">
        <SRU />
      </TabPanel>
    </div>
  );
}
