import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function UnsubscribeModal({ open, onClose, onAccept, datasets }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{t('searchPage:unsubscribeFromDataset')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('searchPage:datasetsIncludes')} {datasets.map(ds => (<b key={ds.dsid}>{ds.name}</b>))}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('button:cancel')}
        </Button>
        <Button onClick={onAccept} color="primary" autoFocus>
          {t('button:accept')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
