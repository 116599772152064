import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import VerticalTable from '../../../common/VerticalTable/VerticalTable';
import styles from '../Profile.module.css';

export default function TargetPanel({ target, onEdit, onRemove }) {
  const { t } = useTranslation();

  const paramPairs = Object.entries(target.data.proto).map(([key, value]) => [t(`profile:${key}`), value]);

  const targetParams = [
    {
      [t('common:name')]: target.name,
    },
    Object.fromEntries(paramPairs),
  ];

  return (
    <div className={styles.infoPanel}>
      <div className={styles.infoPanelHeader}>
        <h3>{t('common:info')}</h3>
        <span>
          <Button variant="contained" color="primary" onClick={onEdit} data-test-id="button-edit-target">
            <EditIcon />
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            className="button-danger"
            onClick={() => onRemove(target.tarid)}
            data-test-id="button-remove-target"
          >
            <DeleteForeverIcon />
          </Button>
        </span>
      </div>
      <VerticalTable data={targetParams} />
    </div>
  );
}
