import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './LoadingOverlay.module.css';

export default function LoadingOverlay (props) {
  return (
    <div className={styles.overlay}>
      <CircularProgress {...props}/>
    </div>
  );
}
