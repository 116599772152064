function transformTimestampToString(timestamp) {
  const date = new Date(timestamp * 1000);
  const offset = date.getTimezoneOffset() * 60 * 1000;
  return String(new Date(date.getTime() + offset)).match(/\d{2}:\d{2}/)?.[0];
}

function transformStringToTimestamp(string) {
  const [hours, minutes] = string.split(':');
  return parseInt(hours) * 60 * 60 + parseInt(minutes) * 60;
}

export function formatRangesToStrings(ranges = []) {
  if (!Array.isArray(ranges)) return [];
  return ranges.map((range) => ({
    timeFrom: transformTimestampToString(range.timeFrom),
    timeTo: transformTimestampToString(range.timeTo),
  }));
}

export function formatRangesToTimestamp(ranges = []) {
  if (!Array.isArray(ranges)) return [];
  return ranges.map((range) => {
    const timeFrom = transformStringToTimestamp(range.timeFrom);
    let timeTo = transformStringToTimestamp(range.timeTo);
    if (timeFrom > timeTo) {
      timeTo += 24 * 60 * 60;
    }
    return {
      timeFrom,
      timeTo,
    };
  });
}
