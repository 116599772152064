import Search from '@material-ui/icons/Search';
import { isDate, format } from 'date-fns';

let store;

export function injectStoreToAPI(_store) {
  store = _store;
}

function createQueryFromParams(params = {}) {
  const paramsPairs = Object.keys(params).map((key) => {
    let paramsString = '';
    if (Array.isArray(params[key])) {
      paramsString = params[key].map((item) => encodeURIComponent(item.value).replaceAll('%20', '+')).join('+or+');
    } else if (isDate(params[key])) {
      paramsString = format(params[key], 'yyyy-MM-dd');
    } else {
      paramsString = params[key];
    }
    return paramsString ? `${key}=${paramsString}` : '';
  });
  const query = paramsPairs.filter((p) => p !== '').join('&');
  return query ? '&' + query : '';
}

function request(method, url, body) {
  const host = store.getState().app.server;
  const query = method === 'GET' ? createQueryFromParams(body) : '';

  let extraHeaders = {};
  const ticket = sessionStorage.getItem('authTicket');
  if (ticket) {
    extraHeaders = { 'X-Ticket': ticket };
  }

  return fetch(`${host}/${url}${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/xml;q=0.9, */*;q=0.8',
      'X-Service': window.location.origin,
      ...extraHeaders,
    },
    body: method !== 'GET' ? JSON.stringify(body) : null,
  })
    .then((res) => {
      // if (res.status === 401) {
      //   const appHost = encodeURIComponent(window.location.href);
      //   const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
      //   sessionStorage.removeItem('authTicket');
      //   window.location = loginUrl;
      //   return;
      // }
      return res.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        throw error;
      }
    );
}

function requestRaw(method, url, body) {
  const host = store.getState().app.server;

  return fetch(`${host}/${url}`, {
    method,
    headers: {
      Accept: 'application/xml',
    },
    body,
  }).then((res) => {
    if (!res.ok) {
      throw new Error(`request error - ${res.status}`);
    }
    return res.blob();
  });
}

function requestAPI(method, url, body) {
  const host = store.getState().app.server;
  return fetch(`${host}/geonetwork/${url}`, {
    method,
    headers: {
      Accept: 'application/xml;q=0.9, */*;q=0.8',
    },
    body,
  })
    .then((res) => {
      // if (res.status === 401) {
      //   const appHost = encodeURIComponent(window.location.href);
      //   const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
      //   sessionStorage.removeItem('authTicket');
      //   window.location = loginUrl;
      //   return;
      // }
      return res.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        throw error;
      }
    );
}

function fetchGroups() {
  return requestAPI('GET', 'srv/api/groups');
}

function fetchMetadata({ search, filters, ...params }) {
  let mask = '';
  Object.keys(filters).forEach((key) => {
    mask += encodeURIComponent(`${key}/${filters[key]}`) + ',';
  });
  mask = mask.slice(0, -1);
  const query = createQueryFromParams(params);
  return request('GET', `metaspace/api/v1/metadata/search?any=${search}&facet.q=${mask}${query}`);
}

function fetchSuggestions(key, input) {
  return request('GET', `metaspace/api/v1/metadata/search/suggest?field=${key}&sortBy=STARTSWITHFIRST&q=${input}`);
}

function fetchMetadataById(id) {
  return request('GET', `metaspace/api/v1/metadata/search?&uuid=${id}`);
}

function openFileForRead(uuid) {
  return request('POST', `metaspace/api/v1/editor/files/open`, { uuid });
}

function readFileFeatures(handle) {
  return request('GET', `metaspace/api/v1/editor/files/${handle}/features`);
}

function createExecutor(compid, job) {
  const params = {
    compid,
    job,
  };
  return request('POST', `executors/api/v1/executors/new`, params);
}

function fetchExecutor(exid) {
  return request('GET', `executors/api/v1/executors/${exid}`);
}

function startExecutor(exid) {
  return request('POST', `executors/api/v1/executors/${exid}/start`);
}

function fetchXML(id) {
  return requestRaw('GET', `metaspace/api/v1/metadata/body/${id}`);
}

function startSruSearch({ search, start, max, version }) {
  return requestRaw(
    'GET',
    `metaspace/sru?operation=searchRetrieve&version=${version}&startRecord=${start}&maximumRecords=${max}&query=${search}`
  );
}

const geoAPI = {
  fetchGroups,
  fetchMetadata,
  fetchSuggestions,
  fetchMetadataById,
  fetchXML,
  startSruSearch,
  openFileForRead,
  readFileFeatures,
  createExecutor,
  fetchExecutor,
  startExecutor,
};

export default geoAPI;
