import { call, put, takeEvery, all } from 'redux-saga/effects';
import casAPI from '../api/casAPI';

function* fetchCompanies() {
  try {
    const response = yield call(casAPI.fetchCompanies);
    yield put({ type: 'app/setCompanies', payload: { companies: response?.info } });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchData() {
  try {
    yield all([fetchCompanies()]);
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

const appSagas = [takeEvery('app/fetchCompanies', fetchCompanies), takeEvery('app/fetchData', fetchData)];

export default appSagas;
