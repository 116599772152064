import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import Input from '../../../common/Input/Input';
import Search from '@material-ui/icons/Search';
import styles from './styles.module.css';

export default function Params({ value, onStart, onChange }) {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    onStart && onStart();
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.searchWrapper}>
          <Input
            value={value.search}
            onChange={onChange('search')}
            label={t('searchPage:search')}
            labelWidth="200px"
            inputWidth="600px"
          />
          <Button
            type="submit"
            style={{ margin: '0 10px' }}
            variant="contained"
            color="primary"
          >
            <Search />
          </Button>
        </div>
        <div className={styles.selectWrapper}>
          <span className={styles.selectLabel}>{t('searchPage:examples')}</span>
          <Select
            onChange={onChange('example')}
            value={value.example}
            placeholder={t('searchPage:selectExample')}
          >
            <MenuItem value="title = climatology">title = climatology</MenuItem>
            <MenuItem value="abstract = climatology">abstract = climatology</MenuItem>
            <MenuItem value="subject = climatology">subject = climatology</MenuItem>
            <MenuItem value="keywords = temperature">keywords = temperature</MenuItem>
            <MenuItem value="keywords = &quot;soil temperature&quot;">keywords = soil temperature</MenuItem>
            <MenuItem value="beginningDate = &quot;20090101&quot;">beginningDate  = "20090101"</MenuItem>
            <MenuItem value="endingDate = &quot;20091231&quot;">endingDate = "20091231"</MenuItem>
            <MenuItem value="creationDate > 20080101">creationDate &gt; 20080101</MenuItem>
            <MenuItem value="publicationDate > 20080101">publicationDate &gt; 20080101</MenuItem>
            <MenuItem value="modificationDate > 20080101">modificationDate &gt; 20080101</MenuItem>
            <MenuItem value="creator = Wetterdienst and title = temperature">creator = Wetterdienst and title = temperature</MenuItem>
            <MenuItem value="Observation">Observation</MenuItem>
            <MenuItem value="geo.bounds within &quot;56.66 -1.58 45.83 20.21&quot;">geo.bounds within "56.66 -1.58 45.83 20.21"</MenuItem>
            <MenuItem value="geo.bounds nwse &quot;56.66 -1.58 45.83 20.21&quot;">geo.bounds nwse "56.66 -1.58 45.83 20.21"</MenuItem>
            <MenuItem value="geo.bounds partial &quot;56.66 -1.58 45.83 20.21&quot;">geo.bounds partial "56.66 -1.58 45.83 20.21"</MenuItem>
            <MenuItem value="geo.bounds within/partial/nwse &quot;56.66 -1.58 45.83 20.21&quot;">geo.bounds within/partial/nwse "56.66 -1.58 45.83 20.21"</MenuItem>
          </Select>
        </div>
        <Input
          value={value.start}
          onChange={onChange('start')}
          label={t('searchPage:startRecord')}
          labelWidth="200px"
          inputWidth="50px"
        />
        <Input
          value={value.max}
          onChange={onChange('max')}
          label={t('searchPage:maxRecord')}
          labelWidth="200px"
          inputWidth="50px"
        />
        <Input
          value={value.version}
          onChange={onChange('version')}
          label={t('searchPage:version')}
          labelWidth="200px"
          inputWidth="50px"
          readOnly
        />
      </form>
    </div>
  );
}
