import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import VerticalTable from '../../../common/VerticalTable/VerticalTable';
import { selectTargets } from '../userSlice';
import QueueSwitch from './QueueSwitch';
import styles from '../Profile.module.css';

export default function QueuePanel({ queue, onEdit, onRemove }) {
  const { t } = useTranslation();
  const targets = useSelector(selectTargets);

  const getTargetNameById = (id) => {
    const target = targets.find((target) => target.tarid === id);
    return target ? target.name : '-';
  };

  const schedule = queue.schedule?.ranges?.length ? (
    <table style={{ width: '100%' }}>
      <thead>
        <tr>
          <td>{t('profile:start')}</td>
          <td>{t('profile:end')}</td>
        </tr>
      </thead>
      <tbody>
        {queue.schedule.ranges.map((range) => (
          <tr key={range.id}>
            <td>{range.timeFrom}</td>
            <td>{range.timeTo}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : null;

  const queueParams = [
    {
      [t('common:id')]: queue.qid,
      [t('common:name')]: queue.caption,
      [t('profile:target')]: getTargetNameById(queue.tarid),
      [t('profile:status')]: <QueueSwitch qid={queue.qid} />,
      [t('profile:schedule')]: schedule,
    },
  ];

  return (
    <div className={styles.infoPanel}>
      <div className={styles.infoPanelHeader}>
        <h3>{t('common:info')}</h3>
        <span>
          <Button variant="contained" color="primary" onClick={onEdit} data-test-id="button-edit-queue">
            <EditIcon />
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="contained"
            className="button-danger"
            onClick={() => onRemove(queue.qid)}
            data-test-id="button-remove-queue"
          >
            <DeleteForeverIcon />
          </Button>
        </span>
      </div>
      <VerticalTable data={queueParams} />
    </div>
  );
}
