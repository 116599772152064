import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from "date-fns";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Table from '../../../common/Table/Table';
import Scrollbar from "../../../common/Scrollbar/Scrollbar";
import LoadingOverlay from '../../../common/LoadingOverlay/LoadingOverlay';
import { downloadFile } from '../../../utils';
import dataAPI from '../../../api/dataAPI';

export default function RecordsModal({ open, records, qrids, onClose, onUpdate }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState({});
  const [hashTable, setHashTable] = useState([]);

  const columns = [
    { id: 'name', label: t('record:name'), minWidth: 200 },
    { id: 'time', label: t('record:time'), format: value => value && format(value * 1000, 'dd.MM.yyyy') },
    { id: 'size', label: t('record:size'), minWidth: 100 },
  ];

  useEffect(() => {
    if (!open) return;
    const fetchMessages = async () => {
      const table = records
        .filter(rec => qrids.includes(rec.qrid))
        .map(rec => ({ qrid: rec.qrid, jrid: rec.content?.jrid }))
      setHashTable(table)
      const jrids = table.map(({ jrid }) => jrid)
      const response = await dataAPI.fetchFullRecords(jrids);
      setMessages(response.jrecs.items);
    };
    setIsLoading(true);
    fetchMessages().catch(() => {
      setMessages([]);
      setSelectedMessage({});
    }).finally(() => setIsLoading(false));
  }, [open])

  const handleRemove = async () => {
    const item = hashTable.find(item => item.jrid === selectedMessage.jrid)
    if (item) {
      await dataAPI.removeRecords([item.qrid]);
      onUpdate();
      const restMessages = messages.filter(msg => msg.jrid !== selectedMessage.jrid)
      setMessages(restMessages)
      setSelectedMessage({})
    }
  }

  const handleDownload = () => {
    downloadFile(selectedMessage.name, `data:application/octet-stream;base64,` + selectedMessage.body, 'raw')
  }

  return (
    <Dialog open={open} onClose={onClose} className="nowidth-limit">
      <DialogContent>
        {isLoading && <LoadingOverlay />}
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Table
              columns={columns}
              rows={messages}
              rowId="jrid"
              onSelect={setSelectedMessage}
              selected={selectedMessage.jrid}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ width: '450px', minHeight: '400px', height: '100%' }}>
              {selectedMessage.jrid &&
                <>
                  <textarea value={selectedMessage.body ? atob(selectedMessage.body) : ''} readOnly style={{ height: '100%', width: '370px', resize: 'none', marginRight: '10px' }} />
                  <div style={{ float: 'right' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDownload}
                    >
                      <GetAppIcon />
                    </Button>
                    <br /><br />
                    <Button
                      variant="contained"
                      className="button-danger"
                      onClick={handleRemove}
                    >
                      <DeleteForeverIcon />
                    </Button>
                  </div>
                </>
              }
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="primary" size="small">
          {t('button:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
