import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Scrollbar from '../../../common/Scrollbar/Scrollbar';
import { format } from 'date-fns';
import VerticalTable from '../../../common/VerticalTable/VerticalTable';
import LoadingOverlay from '../../../common/LoadingOverlay/LoadingOverlay';
import Table from '../../../common/Table/Table';
import { setNotice, selectGroup, selectCompany } from '../../../app/appSlice';

import dataAPI from '../../../api/dataAPI';
import casAPI from '../../../api/casAPI';
import styles from './Search.module.css';

export default function RequestAccessModalNew({ open, data, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const group = useSelector(selectGroup);
  const company = useSelector(selectCompany);
  const [tab, setTab] = useState(0);
  const [datasets, setDatasets] = useState([]);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [datasetsIsLoading, setDatasetsIsLoading] = useState(false);
  const [infoIsLoading, setInfoIsLoading] = useState(false);

  const columns = [{ id: 'uid', label: t('common:id') }];

  const fetchDatasets = async () => {
    setDatasetsIsLoading(true);
    const params = {
      uid: [data.id],
    };
    const { datasets } = await dataAPI.fetchDatasets(params);
    setDatasets(datasets.items);
    setDatasetsIsLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchDatasets();
    } else {
      setDatasets([]);
      setSelectedDataset(null);
      setTab(0);
    }
  }, [open]);

  const handleSelectDataset = (dsid) => async () => {
    setInfoIsLoading(true);
    const { dataset } = await dataAPI.fetchDataset(dsid);
    setSelectedDataset(dataset);
    setInfoIsLoading(false);
  };

  const handleRequestAccess = () => {
    const params = {
      compid: company.id,
      gid: group.gid,
      kind: 'ACCESS',
      caption: 'Запрос на предоставление информации',
      data: {
        text: {
          msg: `Запрос на предоставление доступа к данным - ${data.title}`,
        },
        params: {
          urn: data.id,
          dsid: selectedDataset?.dsid,
        },
      },
    };

    casAPI
      .createRequest(params)
      .then(() => {
        dispatch(setNotice({ type: 'success', text: t('notice:requestSent'), duration: 4000 }));
        onClose();
      })
      .catch((e) => {
        dispatch(setNotice({ type: 'error', text: e }));
      });
  };

  const detailsInfo = selectedDataset
    ? [
        {
          [t('common:name')]: selectedDataset.name,
          [t('common:def')]: selectedDataset.data?.def,
          [t('common:id')]: selectedDataset.dsid,
          [t('common:updatedShort')]: selectedDataset.updated
            ? format(selectedDataset.updated * 1000, 'dd.MM.yyyy HH:mm')
            : '',
        },
      ]
    : [];

  const metadataRows = (selectedDataset?.body?.uid || []).map((uid) => ({ uid }));

  return (
    <Dialog open={open} onClose={onClose} className="nowidth-limit">
      <DialogTitle>{t('searchPage:metadataRequestAccess')}</DialogTitle>
      <DialogContent>
        <div className={styles.ram__wrapper}>
          <p className={styles.ram__title}>{data.title}</p>
          <p className={styles.ram__id}>
            <b>uuid: </b>
            {data.id}
          </p>
          <p>{data.license?.map((obj) => obj.default).join(', ')}</p>
          <div className={styles.ram__content}>
            <div className={styles.ram__contentBlock}>
              <div className={styles.ram__contentHeader}>{t('searchPage:datasets')}</div>
              <div className={styles.ram__contentBody}>
                <Scrollbar>
                  {datasets.map((ds) => (
                    <p
                      key={ds.dsid}
                      onClick={handleSelectDataset(ds.dsid)}
                      className={`${styles.ram__listItem} ${
                        ds.dsid === selectedDataset?.dsid ? styles.ram__listItemActive : ''
                      }`}
                    >
                      {ds.name}
                    </p>
                  ))}
                </Scrollbar>
                {datasetsIsLoading && <LoadingOverlay />}
              </div>
            </div>
            <div className={styles.ram__contentBlock}>
              <div className={styles.ram__contentHeader}>
                <Tabs value={tab} onChange={(e, v) => setTab(v)}>
                  <Tab label={t('searchPage:info')} />
                  <Tab label={t('searchPage:metadata')} />
                </Tabs>
              </div>
              {tab === 0 && (
                <div className={styles.ram__contentBody}>
                  <VerticalTable data={detailsInfo} />
                </div>
              )}
              {tab === 1 && (
                <div className={styles.ram__contentBody}>
                  <Scrollbar>
                    <Table columns={columns} rows={metadataRows} rowId="uid" />
                  </Scrollbar>
                </div>
              )}
              {infoIsLoading && <LoadingOverlay />}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:close')}
        </Button>
        <Button
          variant="contained"
          disabled={!selectedDataset}
          onClick={handleRequestAccess}
          color="primary"
          size="small"
        >
          {t('button:request')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
