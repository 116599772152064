import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { selectGroup, setNotice } from '../../../app/appSlice';
import { selectUser } from '../userSlice';
import casAPI from '../../../api/casAPI';

const textareaStyles = {
  width: '100%',
  height: '200px',
  marginTop: '30px',
  resize: 'none',
};

export default function RequestModal({ open, onClose, onSubmit }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const textarea = useRef();
  const user = useSelector(selectUser);
  const group = useSelector(selectGroup);
  const [titleInput, setTitleInput] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleSendRequest = async () => {
    if (titleInput.length) {
      setHasError(false);
    } else {
      setHasError(true);
      return;
    }
    const data = {
      compid: user.compid,
      gid: group.gid,
      kind: 'ACCESS',
      caption: titleInput,
      data: { text: { msg: textarea.current.value } },
    };
    await casAPI.createSignal(data);
    dispatch(setNotice({ type: 'success', text: t('notice:requestSent'), duration: 2000 }));
    onSubmit();
    onClose();
    setTitleInput('');
  };

  return (
    <Dialog open={open} onClose={onClose} role="modal">
      <DialogTitle>{t('profile:newRequest')}</DialogTitle>
      <DialogContent style={{ width: '600px' }}>
        <TextField
          label={t('common:name')}
          value={titleInput}
          error={hasError}
          onChange={({ target }) => setTitleInput(target.value)}
          margin="dense"
          fullWidth
          required
          variant="outlined"
        />
        <textarea ref={textarea} style={textareaStyles} />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-evenly' }}>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:cancel')}
        </Button>
        <Button onClick={handleSendRequest} className="button-success" size="small">
          {t('button:send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
