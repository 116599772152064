import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Chip from '@material-ui/core/Chip';

import styles from './Showcase.module.css';

export default function Showcase({ onClose, onChange, filters, onReset }) {
  const { t } = useTranslation();

  useEffect(() => {
    onReset();
  }, []);

  const handleSelectCategory = (cat) => () => {
    onChange({ cat });
    onClose();
  };
  const categories = filters.find((filter) => filter.name === 'cat')?.category || [];
  return (
    <div className="row" style={{ height: 'calc(100% - 83px)' }}>
      <div className={`${styles.showcase} col-10`}>
        {categories.map((cat) => (
          <div className={styles.showcaseItem} key={cat.value}>
            <Chip
              className={styles.chipCat}
              label={t(`geoCore:${cat.value}`) + ` (${cat.count})`}
              variant="outlined"
              color="primary"
              onClick={handleSelectCategory(cat.value)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
