let store;

export function injectStoreToAPI(_store) {
  store = _store;
}

function createQueryFromParams(params = {}) {
  const keys = Object.keys(params);
  let query = '';
  keys.forEach((key) => {
    query = `${query}${query ? '&' : '?'}${key}=${params[key]}`;
  });
  return query;
}

function request(method, url, body) {
  const token = btoa(`demo@example.com:12345`);
  const host = store.getState().app.server;
  const query = method === 'GET' ? createQueryFromParams(body) : '';
  return fetch(`${host}/management/api/v1/${url}${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
      'X-Ticket': sessionStorage.getItem('authTicket'),
      'X-Service': window.location.origin,
    },
    body: method !== 'GET' ? JSON.stringify(body) : null,
  })
    .then((res) => {
      // if (res.status === 401) {
      //   const appHost = encodeURIComponent(window.location.href);
      //   const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
      //   sessionStorage.removeItem('authTicket');
      //   window.location = loginUrl;
      //   return;
      // }
      return res.json();
    })
    .then(
      (result) => {
        // if (!result.info.) throw new Error('Fetch error');
        return result;
      },
      (error) => {
        throw error;
      }
    );
}

function fetchCompanies() {
  return request('GET', 'companies/list');
}

const manAPI = {
  fetchCompanies,
};

export default manAPI;
