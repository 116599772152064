import gateways from 'utils/gateways';

let store;

export function injectStoreToAPI(_store) {
  store = _store;
}

function createQueryFromParams(params = {}) {
  const keys = Object.keys(params);
  let query = '';
  keys.forEach((key) => {
    query = `${query}${query ? '&' : '?'}${key}=${params[key]}`;
  });
  return query;
}

function request(method, url, body) {
  const token = btoa(`demo@example.com:12345`);
  const host = store.getState().app.server;
  const query = method === 'GET' ? createQueryFromParams(body) : '';
  return fetch(`${host}/queues/api/v2/${url}${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
      'X-Ticket': sessionStorage.getItem('authTicket'),
      'X-Service': window.location.origin,
    },
    body: method !== 'GET' ? JSON.stringify(body) : null,
  })
    .then((res) => {
      // if (res.status === 401) {
      //   const appHost = encodeURIComponent(window.location.href);
      //   const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
      //   sessionStorage.removeItem('authTicket');
      //   window.location = loginUrl;
      //   return;
      // }
      return res.json();
    })
    .then(
      (result) => {
        const code = result?.meta.code;
        if (code !== 200 && code !== 201) throw result?.meta.error;
        return result?.response;
      },
      (error) => {
        throw error;
      }
    );
}

function requestDataspace(method, url, body, responseFormat) {
  const token = btoa(`demo@example.com:12345`);
  const host = store.getState().app.server;
  const query = method === 'GET' ? createQueryFromParams(body) : '';
  return fetch(`${host}/dataspace/api/v2/${url}${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
      'X-Ticket': sessionStorage.getItem('authTicket'),
      'X-Service': window.location.origin,
    },
    body: method !== 'GET' ? JSON.stringify(body) : null,
  })
    .then((res) => {
      if (res.status === 401) {
        const appHost = encodeURIComponent(window.location.href);
        const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
        sessionStorage.removeItem('authTicket');
        window.location = loginUrl;
        return;
      }
      return responseFormat === 'blob' ? res.blob() : res.json();
    })
    .then(
      (result) => {
        if (responseFormat) return result;
        const code = result?.meta.code;
        if (code !== 200 && code !== 201) throw result?.meta.error;
        return result?.response;
      },
      (error) => {
        throw error;
      }
    );
}

function requestDataserver(method, url, body, responseFormat) {
  const token = btoa(`demo@example.com:12345`);
  const host = store.getState().app.server;
  const query = method === 'GET' ? createQueryFromParams(body) : '';
  return fetch(`${host}/dataserver/api/v2/${url}${query}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Basic ${token}`,
      'X-Ticket': sessionStorage.getItem('authTicket'),
      'X-Service': window.location.origin,
    },
    body: method !== 'GET' ? JSON.stringify(body) : null,
  })
    .then((res) => {
      if (res.status === 401) {
        const appHost = encodeURIComponent(window.location.href);
        const loginUrl = `${process.env.REACT_APP_SERVER}/acl/api/v1/cas/login?service=${appHost}`;
        sessionStorage.removeItem('authTicket');
        window.location = loginUrl;
        return;
      }
      return responseFormat === 'blob' ? res.blob() : res.json();
    })
    .then(
      (result) => {
        if (responseFormat) return result;
        const code = result?.meta.code;
        if (code !== 200 && code !== 201) throw result?.meta.error;
        return result?.response;
      },
      (error) => {
        throw error;
      }
    );
}

function fetchMetainfo(id, startAt) {
  const company = store.getState().app.company.id;
  return requestDataspace('GET', `storage/list?urn=${id}&cmpid=${company}&startAt=${startAt}&flag=body`);
}

function fetchDate(id) {
  return requestDataspace('GET', `storage/list?urn=${id}&count=1`);
}

function fetchQueues(params) {
  return gateways.queueSchedule.fromServer(request('POST', `queues/list`, params));
}

function fetchRecords(qid) {
  return request('GET', `queues/${qid}/list`);
}

function fetchRecord(id) {
  return requestDataspace('GET', `storage/${id}`);
}

function removeRecords(qrids) {
  return request('POST', `queues/rec_status?status=del&who=web`, { qrids });
}

function fetchTargets(params) {
  return request('GET', `targets/list`, params);
}

function createTarget(data) {
  return request('POST', `targets/new`, data);
}

function updateTarget(tarid, data) {
  return request('POST', `targets/${tarid}/update`, data);
}

function archiveTarget(tarid) {
  return request('POST', `targets/archive?tarid=${tarid}`);
}

function restoreTarget(tarid) {
  return request('POST', `targets/restore?tarid=${tarid}`);
}

function removeTarget(tarid) {
  return request('POST', `targets/rem?tarid=${tarid}`);
}

function createQueue(data) {
  return request('POST', `queues/new`, data);
}

function updateQueue(id, data) {
  const formattedData = gateways.queueSchedule.toServer(data);
  return request('POST', `queues/${id}/update`, formattedData);
}

function updateQueueRaw(id, data) {
  return request('POST', `queues/${id}/update`, data);
}

function removeQueue(id) {
  return request('POST', `queues/rem?qid=${id}`);
}

function archiveQueue(qid) {
  return request('POST', `queues/archive?qid=${qid}`);
}

function restoreQueue(qid) {
  return request('POST', `queues/restore?qid=${qid}`);
}

function clearQueue(qid) {
  return request('POST', `queues/${qid}/clear?who=web`);
}

function fetchDatasets(params) {
  return request('GET', 'datasets/list', params);
}

function fetchDataset(id) {
  return request('GET', `datasets/${id}`);
}

function checkMetaInDataset(id) {
  const data = {
    products: [id],
  };
  return request('POST', 'datasets/check', data);
}

function checkDataset(data) {
  return request('POST', 'datasets/check', data);
}

function downloadRecords(jrid) {
  return requestDataspace('POST', 'storage/download', { jrid, arcType: 'zip' }, 'blob');
}

function downloadFile(id, withBody) {
  return requestDataspace('GET', `filelinks/${id}${withBody ? '?flag=body' : ''}`);
}

function fetchFullRecords(jrid) {
  return requestDataspace('POST', 'storage/list?flag=body', { jrid });
}

function fetchRecordsFromStorage(jrid) {
  return requestDataspace('POST', 'storage/list', { jrid });
}

function fetchFeaturePoints(urn) {
  return requestDataserver('POST', 'sources/list?flag=binding&pagesize=10', { urn: [urn] });
}

const dataAPI = {
  fetchMetainfo,
  fetchDate,
  fetchQueues,
  fetchRecords,
  fetchRecordsFromStorage,
  fetchRecord,
  fetchFullRecords,
  checkDataset,
  removeRecords,
  downloadRecords,
  downloadFile,
  fetchTargets,
  createTarget,
  updateTarget,
  archiveTarget,
  restoreTarget,
  removeTarget,
  createQueue,
  updateQueue,
  removeQueue,
  archiveQueue,
  restoreQueue,
  updateQueueRaw,
  clearQueue,
  fetchDatasets,
  fetchDataset,
  checkMetaInDataset,
  fetchFeaturePoints,
};

export default dataAPI;
