import { formatRangesToStrings, formatRangesToTimestamp } from './schedule.js';
import { insertId, extractId } from 'utils';

const queueSchedule = {
  fromServer: async (request) => {
    const response = await request;
    const items = response.queues.items.map((item) => ({
      ...item,
      schedule: {
        ranges: insertId(formatRangesToStrings(item.schedule?.ranges)),
      },
    }));

    return { queues: { ...response.queues, items } };
  },
  toServer: (data) => {
    return {
      ...data,
      schedule: {
        ranges: formatRangesToTimestamp(extractId(data.schedule?.ranges)),
      },
    };
  },
};

const gateways = {
  queueSchedule,
};

export default gateways;
