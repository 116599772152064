import { useState } from 'react';
import { useSelector } from 'react-redux';
import { sub, set, getDay, getMonth, getYear } from 'date-fns';
import { useTranslation } from 'react-i18next';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Grid, Button, Paper } from '@material-ui/core';
import Menu from '@material-ui/icons/Menu';
import Search from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import AutocompleteSelect from '../../../common/AutocompleteSelect/AutocompleteSelect';
import Autosuggest from '../../../common/Autosuggest/Autosuggest';
import Dropdown from '../../../common/Dropdown/Dropdown';
import { selectLang } from '../../../app/appSlice';
import geoAPI from '../../../api/geoAPI';
import styles from './Search.module.css';

const dropdownMenu = [
  { value: 'today', label: 'Сегодня' },
  { value: 'yesterday', label: 'Вчера' },
  { value: 'week', label: 'На этой неделе' },
  { value: 'month', label: 'В этом месяце' },
  { value: 'month3', label: 'За последние 3 месяца' },
  { value: 'month6', label: 'За последние 6 месяцев' },
  { value: 'year', label: 'В этом году' },
];

export default function Params({ value, onStart, onChange, onReset }) {
  const { t } = useTranslation();
  const lang = useSelector(selectLang);
  const [showParams, setShowParams] = useState(false);

  const loadOptions = (key) => async (inputValue) => {
    try {
      const [, suggestions] = await geoAPI.fetchSuggestions(key, inputValue);
      return suggestions.map((item) => ({ value: item, label: item }));
    } catch (e) {
      return [];
    }
  };

  const toggleParams = () => {
    setShowParams((state) => !state);
  };

  const setDate = (menu) => (period) => {
    let offset;
    let dateFrom;
    const now = new Date();
    let dateTo = sub(now, { days: -1 });
    if (period === 'today') {
      offset = {
        days: 1,
      };
      dateFrom = sub(dateTo, offset);
    } else if (period === 'yesterday') {
      offset = {
        days: 1,
      };
      dateTo = sub(dateTo, offset);
      dateFrom = sub(dateTo, offset);
    } else if (period === 'week') {
      const day = getDay(dateTo);
      offset = {
        days: day ? day - 1 : 6,
      };
      dateFrom = sub(dateTo, offset);
    } else if (period === 'month') {
      dateFrom = set(now, { date: 1 });
    } else if (period === 'month3') {
      const month = getMonth(now) - 3;
      dateFrom = set(now, { date: 1, month });
    } else if (period === 'month6') {
      const month = getMonth(now) - 6;
      dateFrom = set(now, { date: 1, month });
    } else if (period === 'year') {
      const year = getYear(now);
      dateFrom = set(now, { date: 1, month: 0, year });
    }
    let dateFromLabel, dateToLabel;
    // eslint-disable-next-line default-case
    switch (menu) {
      case 'resources':
        dateFromLabel = 'creationDateFrom';
        dateToLabel = 'creationDateTo';
        break;
      case 'records':
        dateFromLabel = 'dateFrom';
        dateToLabel = 'dateTo';
        break;
      case 'resourceDateRange':
        dateFromLabel = 'resourceDateRangeFrom';
        dateToLabel = 'resourceDateRangeTo';
        break;
    }

    onChange(dateFromLabel)(dateFrom);
    onChange(dateToLabel)(dateTo);
  };

  const innerParamsClass = `${styles.innerParams} ${showParams ? styles.innerShow : ''}`;

  const hasActiveParams =
    Boolean(value.cat.length) ||
    Boolean(value.keyword.length) ||
    Boolean(value.orgName.length) ||
    Boolean(value.creationDateFrom) ||
    Boolean(value.creationDateTo) ||
    Boolean(value.resourceDateRangeFrom) ||
    Boolean(value.resourceDateRangeTo) ||
    Boolean(value.dateFrom) ||
    Boolean(value.dateTo);

  const handleClearSearch = () => {
    onChange('search')('');
    onStart(null, '');
  };

  return (
    <Paper className={styles.params}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onStart();
        }}
        className={styles.inputWrapper}
      >
        <div className={styles.autosuggest}>
          <Autosuggest
            className={styles.searchInput}
            onSuggestionSelected={(e, { suggestionValue }) => onStart(e, suggestionValue)}
            value={value.search}
            onChange={onChange('search')}
            loadOptions={loadOptions('anylight')}
          />
          {value.search && (
            <span className={styles.clearInput} onClick={handleClearSearch}>
              <CloseIcon />
            </span>
          )}
        </div>
        <Button
          type="submit"
          data-test-id="search-button"
          style={{ margin: '0 10px' }}
          variant="contained"
          color="primary"
        >
          <Search />
        </Button>

        <ButtonGroup color="primary" size="small">
          <Button
            className={hasActiveParams ? styles.activatedButton : ''}
            variant="outlined"
            color="primary"
            onClick={toggleParams}
          >
            <Menu />
          </Button>
          {hasActiveParams && (
            <Button
              className={hasActiveParams ? styles.activatedButton : ''}
              variant="outlined"
              color="primary"
              onClick={onReset}
            >
              <CloseIcon />
            </Button>
          )}
        </ButtonGroup>
      </form>
      <Grid container justify="center" spacing={3} className={innerParamsClass}>
        <Grid item xs={6}>
          <div className={styles.selectWrapper}>
            <div className={styles.selectLabel}>{t('geoCore:categories')}</div>
            {showParams && (
              <AutocompleteSelect
                onChange={onChange('cat')}
                loadOptions={loadOptions('cat')}
                value={value.cat}
                isMulti
                defaultOptions
              />
            )}
          </div>
          <div className={styles.selectWrapper}>
            <div className={styles.selectLabel}>{t('geoCore:keywords')}</div>
            {showParams && (
              <AutocompleteSelect
                onChange={onChange('keyword')}
                loadOptions={loadOptions('keyword')}
                value={value.keyword}
                isMulti
                defaultOptions
              />
            )}
          </div>
          <div className={styles.selectWrapper}>
            <div className={styles.selectLabel}>{t('geoCore:orgNames')}</div>
            {showParams && (
              <AutocompleteSelect
                onChange={onChange('orgName')}
                loadOptions={loadOptions('orgName')}
                value={value.orgName}
                isMulti
                defaultOptions
              />
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container spacing={5}>
              <Grid item>
                <div className={styles.selectWrapper}>
                  <div className={styles.dateLabel}>
                    <Dropdown items={dropdownMenu} onClick={setDate('resources')}>
                      <div className="link">{t('geoCore:resourcesCreateDate')}</div>
                    </Dropdown>
                  </div>
                  <KeyboardDatePicker
                    value={value.creationDateFrom}
                    onChange={onChange('creationDateFrom')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="От"
                    format="dd.MM.yyyy"
                  />
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <KeyboardDatePicker
                    value={value.creationDateTo}
                    onChange={onChange('creationDateTo')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="До"
                    format="dd.MM.yyyy"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className={styles.selectWrapper}>
                  <div className={styles.dateLabel}>
                    <Dropdown items={dropdownMenu} onClick={setDate('records')}>
                      <div className="link">{t('geoCore:recordsCreateDate')}</div>
                    </Dropdown>
                  </div>
                  <KeyboardDatePicker
                    value={value.dateFrom}
                    onChange={onChange('dateFrom')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="От"
                    format="dd.MM.yyyy"
                  />
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <KeyboardDatePicker
                    value={value.dateTo}
                    onChange={onChange('dateTo')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="До"
                    format="dd.MM.yyyy"
                  />
                </div>
              </Grid>
              <Grid item>
                <div className={styles.selectWrapper}>
                  <div className={styles.dateLabel}>
                    <Dropdown items={dropdownMenu} onClick={setDate('resourceDateRange')}>
                      <div className="link">{t('geoCore:resourceDateRange')}</div>
                    </Dropdown>
                  </div>
                  <KeyboardDatePicker
                    value={value.resourceDateRangeFrom}
                    onChange={onChange('resourceDateRangeFrom')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="От"
                    format="dd.MM.yyyy"
                  />
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <KeyboardDatePicker
                    value={value.resourceDateRangeTo}
                    onChange={onChange('resourceDateRangeTo')}
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    label="До"
                    format="dd.MM.yyyy"
                  />
                </div>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Paper>
  );
}
