import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from '@material-ui/core/Button';
import Table from '../../../common/Table/Table';
import Scrollbar from '../../../common/Scrollbar/Scrollbar';
import RequestPanel from './RequestPanel';
import RequestModal from './RequestModal';
import { selectUser, selectRequests, fetchRequests, removeRequest } from '../userSlice';
import styles from '../Profile.module.css';
import { getDateUTC } from 'utils';

export default function Requests() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const requests = useSelector(selectRequests);
  const user = useSelector(selectUser);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [checkedRequests, setCheckedRequests] = useState([]);
  const [showRequestModal, setShowRequestModal] = useState(false);

  const columns = [
    { id: 'caption', sortable: true, label: t('common:name'), minWidth: 170 },
    {
      id: 'created',
      sortable: true,
      label: t('common:created'),
      format: (value) => format(getDateUTC(value * 1000), 'HH:mm dd.MM.yyyy'),
    },
    {
      id: 'updated',
      sortable: true,
      label: t('common:updated'),
      format: (value) => format(getDateUTC(value * 1000), 'HH:mm dd.MM.yyyy'),
    },
    { id: 'data', label: t('profile:messageBody'), format: (data) => data?.text?.msg },
    { key: 'comment', id: 'data', label: t('profile:messageAnswer'), format: (data) => data?.text?.comm },
  ];

  const onSubmitRequestModal = () => {
    updateList();
  };

  const updateList = () => dispatch(fetchRequests(user));

  const handleRemoveSignals = () => {
    const requestsForRemove = requests.filter((request) => checkedRequests.includes(request.reqid));
    const refresh = () => {
      updateList();
      setCheckedRequests([]);
    };
    dispatch(removeRequest({ request: requestsForRemove, cb: refresh }));
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <div className={styles.tabContent}>
      <div className={styles.sections}>
        <div className={styles.leftSection}>
          <h3 className={styles.title}>
            <Button
              className={`${styles.headerButton} button-success`}
              variant="contained"
              endIcon={<SendIcon />}
              onClick={() => setShowRequestModal(true)}
            >
              {t('profile:sendRequest')}
            </Button>
            {t('profile:requests')}
            <Button
              className={`${styles.headerButton_right} button-danger`}
              variant="contained"
              disabled={!checkedRequests.length}
              onClick={handleRemoveSignals}
            >
              <DeleteForeverIcon />
            </Button>
          </h3>
          <Scrollbar>
            <Table
              columns={columns}
              rows={requests}
              rowId="reqid"
              onSelect={setSelectedRequest}
              selected={selectedRequest && selectedRequest.reqid}
              checkbox
              onCheck={setCheckedRequests}
              checked={checkedRequests}
              extraClass="archived"
              extraCondition={(row) => row.archived}
            />
          </Scrollbar>
        </div>
        <div className={styles.rightSection}>
          <RequestPanel request={selectedRequest} />
        </div>
      </div>
      <RequestModal
        open={showRequestModal}
        onClose={() => setShowRequestModal(false)}
        onSubmit={onSubmitRequestModal}
      />
    </div>
  );
}
