import { MapContainer, TileLayer, Rectangle, useMap, useMapEvents } from 'react-leaflet';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Crop54Icon from '@material-ui/icons/Crop54';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import Dropdown from '../../../common/Dropdown/Dropdown';
import 'leaflet/dist/leaflet.css'
import styles from './Search.module.css';

function MapDrawer({ active, onComplete, fitBounds }) {
  const [drawing, setDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState(null);
  const [endPoint, setEndPoint] = useState(null);
  const map = useMap();
  active ? map.dragging.disable() : map.dragging.enable();

  if (fitBounds) {
    map.fitBounds(fitBounds);
  }

  useEffect(() => {
    if (!active) {
      setStartPoint(null);
      setEndPoint(null);
      onComplete('');
    }
  }, [active, onComplete]);

  useMapEvents({
    mousedown: (e) => {
      if (!active) return;
      setDrawing(true);
      setStartPoint(e.latlng);
      setEndPoint(null);
    },
    mouseup: () => {
      if (!active) return;
      setDrawing(false);
      if (startPoint && endPoint) {
        const polygon = `POLYGON((${startPoint.lng}+${startPoint.lat},${startPoint.lng}+${endPoint.lat},${endPoint.lng}+${endPoint.lat},${endPoint.lng}+${startPoint.lat},${startPoint.lng}+${startPoint.lat}))`;
        onComplete(polygon);
      }
    },
    mousemove: (e) => {
      if (!drawing) return;
      setEndPoint(e.latlng);
    },
  })
  if (startPoint && endPoint) {
    return <Rectangle bounds={[startPoint, endPoint]} pathOptions={{ color: '#f90', fillColor: '#ff9900e6' }} />
  }
  return null;

}

export default function Map({ data, selected, onChange, onClose, noControls, fitBounds, zoomControl }) {
  const { t } = useTranslation();
  const [drawMode, setDrawMode] = useState(false);
  const [type, setType] = useState('');


  const dropdownItems = [
    { label: t('searchPage:contains'), value: 'contains' },
    { label: t('searchPage:intersects'), value: 'intersects' },
    { label: t('searchPage:within'), value: 'within' },
  ];

  const onChangeType = (type) => {
    setType(type);
    onChange('relation')(type);
  }

  const onComplete = useCallback((data) => onChange('geometry')(data), []);

  return (
    <div className="mapWrapper">
      {
        !noControls &&
        <>
          <div className={`${styles.mapDrawButton} ${drawMode ? styles.mapDrawButton_active : ''}`} onClick={() => setDrawMode(state => !state)}>
            <Crop54Icon />
          </div>
          <div className={styles.mapDropdown}>
            <Dropdown
              items={dropdownItems}
              value={type}
              onClick={onChangeType}
            >
              <div className={styles.mapSelectButton}>
                <ArrowDropDownIcon />
              </div>
            </Dropdown>
          </div>
        </>
      }
      <MapContainer center={[51.505, -0.09]} zoomControl={zoomControl} zoom={2} style={{ marginTop: '-25px' }}>
        <TileLayer
          continuousWorld="false" noWrap="true"
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {data.filter(Boolean).map((bounds, i) =>
          <Rectangle key={i} bounds={bounds} pathOptions={{ fillColor: 'none' }} />
        )}
        {selected && <Rectangle bounds={selected} pathOptions={{ fillColor: '#f90' }} />}
        <MapDrawer active={drawMode} onComplete={onComplete} fitBounds={fitBounds} />
      </MapContainer>
      {
        !noControls &&
        <div className={styles.mapCloseButton} onClick={onClose}>
          <CloseIcon />
        </div>
      }
    </div>
  );
}
