import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import TabPanel from '../../common/TabPanel/TabPanel';
import Records from './Records/Records';
import Subscriptions from './Subscriptions/Subscriptions';
import Requests from './Requests/Requests';
// import Datasets from './Datasets/Datasets';

export default function Profile() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="page profile-page">
      <Paper square>
        <Tabs
          value={activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setActiveTab(v)}
        >
          <Tab label={t('profile:common')} />
          <Tab label={t('profile:subscriptions')} />
          <Tab label={t('profile:records')} />
          <Tab label={t('profile:requests')} />
          {/* <Tab label={t('profile:datasets')} /> */}
        </Tabs>
      </Paper>
      <TabPanel value={activeTab} index={0} className="full-height-tab">
        <div></div>
      </TabPanel>
      <TabPanel value={activeTab} index={1} className="full-height-tab">
        <Subscriptions />
      </TabPanel>
      <TabPanel value={activeTab} index={2} className="full-height-tab">
        <Records />
      </TabPanel>
      <TabPanel value={activeTab} index={3} className="full-height-tab">
        <Requests />
      </TabPanel>
      {/* <TabPanel value={activeTab} index={3} className="full-height-tab">
        <Datasets />
      </TabPanel> */}
    </div>
  );
}
