import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setNotice, selectCompany } from '../../../app/appSlice';
import { selectTargets, selectDatasets } from '../userSlice';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { updateFiltersWithDatasets, getDatasetsFromFilters } from '../../../utils/filters';
import Schedule from 'features/schedule/Schedule';
import dataAPI from '../../../api/dataAPI';

QueueModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  data: PropTypes.object,
};

const initialState = {
  caption: '',
  tarid: '00000000-0000-0000-0000-000000000000',
  filters: [],
};

const initialValidationState = {
  caption: true,
};

export default function QueueModal({ open, onClose, onSubmit, mode, data, userId }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const company = useSelector(selectCompany);
  const targets = [
    { tarid: '00000000-0000-0000-0000-000000000000', name: t('common:notSelect') },
    ...useSelector(selectTargets),
  ];
  const [values, setValues] = useState(initialState);
  const [validationState, setValidationState] = useState(initialValidationState);
  const datasets = useSelector(selectDatasets);
  const [selectedDatasets, setSelectedDatasets] = useState([]);

  const clearForm = () => {
    setValues(initialState);
    setValidationState(initialValidationState);
    setSelectedDatasets([]);
  };

  useEffect(() => {
    if (mode === 'create') {
      clearForm();
    } else if (data) {
      setValidationState(initialValidationState);
      setValues({ caption: data.caption, tarid: data.tarid, filters: data.filters });
      setSelectedDatasets(getDatasetsFromFilters(data.filters || []));
    }
  }, [mode, data]);

  const validateForm = () => {
    const validated = {};
    let hasErrors = false;
    Object.keys(validationState).forEach((key) => {
      let isValid = !!values[key].length;
      validated[key] = isValid;
      if (!isValid) {
        hasErrors = true;
      }
    });
    setValidationState(validated);
    return !hasErrors;
  };

  const createHandler = () => {
    if (!validateForm()) return;
    dataAPI
      .createQueue({
        ...values,
        cmpid: company.id,
        uid: userId,
        filters: updateFiltersWithDatasets(values.filters, selectedDatasets),
        schedule: { ranges: getRangesFromSchedule() },
      })
      .then(() => {
        onClose();
        onSubmit();
        dispatch(setNotice({ type: 'success', text: t('notice:createQueue'), duration: 2000 }));
        clearForm();
      })
      .catch((e) => dispatch(setNotice({ type: 'error', text: e })));
  };

  const editHandler = () => {
    if (!validateForm()) return;
    dataAPI
      .updateQueue(data.qid, {
        ...values,
        filters: updateFiltersWithDatasets(values.filters, selectedDatasets),
        schedule: { ranges: getRangesFromSchedule() },
      })
      .then(() => {
        onClose();
        onSubmit();
        dispatch(setNotice({ type: 'success', text: t('notice:update'), duration: 2000 }));
        clearForm();
      })
      .catch((e) => dispatch(setNotice({ type: 'error', text: e })));
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSelectDataset = (e) => {
    setSelectedDatasets(e.target.value);
  };

  const getRangesFromSchedule = () => {
    const result = [];
    const ranges = document.querySelectorAll('.schedule .schedule-range');
    ranges.forEach((range) => {
      const timeFrom = range.querySelector('.schedule-range__from input').value;
      const timeTo = range.querySelector('.schedule-range__to input').value;
      result.push({ timeFrom, timeTo });
    });
    return result;
  };

  const title = mode === 'create' ? t('profile:newQueue') : t('profile:editQueue');
  const actionButton =
    mode === 'create' ? (
      <Button onClick={createHandler} className="button-success" size="small">
        {t('button:create')}
      </Button>
    ) : (
      <Button onClick={editHandler} className="button-success" size="small">
        {t('button:save')}
      </Button>
    );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={{ width: '600px' }}>
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <TextField
              label={t('common:name')}
              value={values.caption}
              error={!validationState.caption}
              onChange={handleChange('caption')}
              required
              margin="dense"
              id="caption"
              fullWidth
              variant="outlined"
            />
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%', marginTop: '10px', marginBottom: '5px' }}
            >
              <InputLabel id="target-select-label">{t('profile:target')}</InputLabel>
              <Select
                labelId="target-select-label"
                id="target-select"
                value={values.tarid}
                label={t('profile:target')}
                onChange={handleChange('tarid')}
              >
                {targets.map((target) => (
                  <MenuItem key={target.tarid} value={target.tarid}>
                    {target.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%', marginTop: '10px', marginBottom: '15px' }}
            >
              <InputLabel id="dataset-select-label">{t('profile:dataset')}</InputLabel>
              <Select
                labelId="dataset-select-label"
                id="dataset-select"
                multiple
                value={selectedDatasets}
                label={t('profile:dataset')}
                onChange={handleSelectDataset}
              >
                {datasets.map((ds) => (
                  <MenuItem key={ds.dsid} value={ds.dsid}>
                    {ds.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={5}>
            <h5>{t('profile:schedule')}</h5>
            <Schedule values={data?.schedule?.ranges} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:cancel')}
        </Button>
        {actionButton}
      </DialogActions>
    </Dialog>
  );
}
