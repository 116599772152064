import { Form, Accordion, useAccordionToggle, AccordionContext } from 'react-bootstrap';
import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeConsistent } from '../../../utils';
import LoadingOverlay from '../../../common/LoadingOverlay/LoadingOverlay';
import Panel from '../../../common/Panel/Panel';
import styles from './Search.module.css';
import { CaretRightFill } from 'react-bootstrap-icons';

export default function Filters({ loading, items, active, onChange, onReset }) {
  const { t } = useTranslation();
  const [collapsedAll, setCollapsedAll] = useState(false);

  const filters = items
    .map(makeConsistent({ array: ['category'], removeAtSign: true }))
    .filter((item) => item.category.length);

  const handleCatClick = (name, value) => () => {
    const newMask = { ...active };
    if (newMask[name] && newMask[name] === value) {
      delete newMask[name];
    } else {
      newMask[name] = value;
    }
    onChange(newMask);
  };

  const handleSpoilerClick = (e) => {
    e.target.parentElement.classList.toggle('expanded');
  };

  const header = (
    <div className={styles.filtersHeader}>
      <h5>{t('searchPage:filter')}</h5>
      {filters.length > 0 && (
        <div className={styles.filtersHeaderButtons}>
          <div className="link" onClick={() => setCollapsedAll(!collapsedAll)}>
            {collapsedAll ? t('searchPage:expand') : t('searchPage:collapse')}
          </div>
          <div className="link" onClick={onReset}>
            {t('searchPage:reset')}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Panel header={header}>
      {filters.length ? (
        filters.map((filter) => (
          <Accordion key={filter.name} defaultActiveKey="1">
            <div>
              <ContextAwareToggle eventKey="1" collapsed={collapsedAll}>
                {t(`geoCore:${filter.label}`)}
              </ContextAwareToggle>
              <Accordion.Collapse eventKey="1">
                <Form>
                  <div className="spoiler">
                    <div className="spoiler-content">
                      {filter.category.map(makeConsistent({ removeAtSign: true })).map((cat) => (
                        <Form.Check id={`check-${filter.name}-${cat.value}`} key={cat.value} type="checkbox">
                          <Form.Check.Input
                            type="checkbox"
                            checked={active[filter.name] === cat.value}
                            onChange={handleCatClick(filter.name, cat.value)}
                          />
                          <Form.Check.Label>
                            {cat.label} <strong>({cat.count})</strong>
                          </Form.Check.Label>
                        </Form.Check>
                      ))}
                    </div>
                    {filter.category.length > 5 && (
                      <div className="spoiler-show link" onClick={handleSpoilerClick}>{`${t('searchPage:all')} (${
                        filter.category.length
                      })`}</div>
                    )}
                    <div className="spoiler-hide link" onClick={handleSpoilerClick}>
                      {t('searchPage:collapse')}
                    </div>
                  </div>
                </Form>
              </Accordion.Collapse>
            </div>
          </Accordion>
        ))
      ) : (
        <div className="text-center">
          <br />
          <br />
          <br />
        </div>
      )}
      {loading && <LoadingOverlay />}
    </Panel>
  );
}

function ContextAwareToggle({ children, eventKey, collapsed }) {
  const [expand, setExpand] = useState(true);
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(eventKey, () => setExpand(!expand));

  useEffect(() => {
    if (expand === collapsed) decoratedOnClick();
  }, [collapsed]);

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <h6
      type="button"
      className={`${styles.filterTitle} ${isCurrentEventKey ? styles.filterTitleOpen : styles.filterTitleClose}`}
      onClick={decoratedOnClick}
    >
      <CaretRightFill></CaretRightFill>
      {children}
    </h6>
  );
}
