import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Table from '../../../common/Table/Table';
import { setNotice } from '../../../app/appSlice';

import dataAPI from '../../../api/dataAPI';
import casAPI from '../../../api/casAPI';

export default function RequestAccessModal({ open, id, onClose }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rows, setRows] = useState([]);

  const columns = [{ id: 'name', label: t('profile:dataset'), minWidth: 400 }];

  const fetchDatasets = async () => {
    const params = {
      uid: [id],
    };
    const { datasets } = await dataAPI.checkDataset(params);
    const rows = datasets.map(({ name, dsid }) => ({ name, dsid }));
    setRows(rows);
  };

  useEffect(() => {
    if (!open) return;
    setSelectedRows([]);
    fetchDatasets();
  }, [open]);

  const handleCheckRow = (ids) => {
    setSelectedRows(ids);
  };

  //new modern architecture principle aka "we don't need backend"
  const handleRequestAccess = async () => {
    const promises = selectedRows.map((dsid) =>
      dataAPI.fetchDatasets({ dsid }).then(({ datasets }) => ({ compid: datasets.items[0]?.compid, dsid }))
    );
    const result = await Promise.all(promises);
    const compidToDsid = result.reduce((acc, item) => {
      if (acc[item.compid]) {
        acc[item.compid] = acc[item.compid].push(item.dsid);
      } else {
        acc[item.compid] = [item.dsid];
      }
      return acc;
    }, {});
    const promises2 = Object.keys(compidToDsid).map((compid) =>
      casAPI.fetchGroups({ compid: [compid], searchStr: 'Администраторы', search: ['name'] })
    );
    const result2 = await Promise.all(promises2);
    const CompidToGid = result2[0]?.groups.items.reduce((acc, item) => {
      acc[item.compid] = item.gid;
      return acc;
    }, {});

    Object.keys(compidToDsid).forEach((compid) => {
      const data = {
        compid,
        gid: CompidToGid[compid],
        kind: 'ACCESS',
        caption: 'Запрос на предоставление доступа к данным',
        data: {
          text: {
            msg: 'Прошу предоставить доступ к наборам данных:',
          },
          datasets: compidToDsid[compid],
        },
      };
      casAPI.createSignal(data).then(() => {
        onClose();
        dispatch(
          setNotice({
            type: 'success',
            text: t('notice:requestSent'),
            duration: 2000,
          })
        );
      });
    });
  };

  return (
    <Dialog open={open} onClose={onClose} className="nowidth-limit">
      <DialogContent>
        <Table columns={columns} rows={rows} rowId="dsid" checkbox onCheck={handleCheckRow} checked={selectedRows} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:close')}
        </Button>
        <Button variant="contained" onClick={handleRequestAccess} color="primary" size="small">
          {t('button:request')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
