import Scrollbar from "../Scrollbar/Scrollbar";
import Paper from '@material-ui/core/Paper';
import styles from './Panel.module.css';

export default function Panel({ header, elevation = 1, children }) {
  return (
    <Paper className={styles.panel} elevation={elevation}>
      {header ? <div className={styles.header}>{header}</div> : null}
      <div className={header ? styles.bodyWithHeader : styles.body} data-testid="content">
        <Scrollbar>
          {children}
        </Scrollbar>
      </div>
    </Paper>
  )
}
