import { useState, useRef, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import AutosuggestHighlightMatch from 'autosuggest-highlight/match';
import AutosuggestHighlightParse from 'autosuggest-highlight/parse';
import { useTranslation } from 'react-i18next';
import './styles.css';

const getSuggestionValue = suggestion => suggestion.value;

function renderSuggestion(suggestion, { query }) {
  const matches = AutosuggestHighlightMatch(suggestion.label, query);
  const parts = AutosuggestHighlightParse(suggestion.label, matches);

  return (
    <span className="suggestion-content">
      <span>
        {
          parts.map((part, index) => {
            const className = part.highlight ? 'highlight' : null;

            return (
              <span className={className} key={index}>{part.text}</span>
            );
          })
        }
      </span>
    </span>
  );
}

export default function AutosuggestComponent({ value, onChange, loadOptions, ...props }) {
  const { t } = useTranslation();
  const [suggestions, setSugesstions] = useState([]);
  const inputRef = useRef();

  const onSuggestionsFetchRequested = async ({ value }) => {
    const suggestions = await loadOptions(value);
    setSugesstions(suggestions);
  };

  const onSuggestionsClearRequested = () => {
    setSugesstions([]);
  };

  const inputProps = {
    placeholder: t('common:search') + '...',
    value,
    onChange: (e, { newValue }) => onChange(newValue)
  };

  useEffect(() => {
    if (!value) {
      inputRef.current.input.focus();
    }
  }, [value]);

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      ref={inputRef}
      {...props}
    />
  );
}
