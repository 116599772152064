import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  metaList: [],
  filtersList: [],
  activeFilters: {},
  pagination: {
    page: 0,
    rowsPerPage: 25,
    count: 0,
  },
  searchParams: {
    search: '',
    cat: [],
    keyword: [],
    orgName: [],
    creationDateFrom: null,
    creationDateTo: null,
    resourceDateRangeFrom: null,
    resourceDateRangeTo: null,
    dateFrom: null,
    dateTo: null,
    resultType: 'details',
    sortBy: 'relevance',
    sortOrder: 'desc',
  },
  resetCounter: 0,
};

export const metaSlice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setMetaList: (state, action) => {
      state.metaList = action.payload;
    },
    setFiltersList: (state, action) => {
      state.filtersList = action.payload;
    },
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload;
      state.pagination.page = 0;
    },
    resetActiveFilters: (state, action) => {
      state.activeFilters = {};
      state.pagination.page = 0;
    },
    updatePagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    updateSearchParams: (state, action) => {
      state.searchParams = {
        ...state.searchParams,
        ...action.payload,
      };
      if (action.payload.sortBy) {
        state.pagination.page = 0;
        state.searchParams.sortOrder = action.payload.sortBy === 'title' ? 'asc' : 'desc';
      }
    },
    resetSearchParams: (state) => {
      state.searchParams = initialState.searchParams;
      state.resetCounter = state.resetCounter += 1;
    },
    replaceState: (state, action) => {
      const { params, pagination, activeFilters } = action.payload;
      state.searchParams = params;
      state.pagination = pagination;
      state.activeFilters = activeFilters;
    },
  },
});

export const {
  setMetaList,
  setFiltersList,
  setActiveFilters,
  resetActiveFilters,
  updatePagination,
  resetSearchParams,
  updateSearchParams,
  replaceState,
} = metaSlice.actions;

export const selectMetaList = (state) => state.meta.metaList;
export const selectFiltersList = (state) => state.meta.filtersList;
export const selectActiveFilters = (state) => state.meta.activeFilters;
export const selectPagination = (state) => state.meta.pagination;
export const selectSearchParams = (state) => state.meta.searchParams;

export default metaSlice.reducer;
