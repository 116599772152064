export function updateFiltersWithDatasets(filters, datasets) {
  filters ??= [];
  const excFilters = filters.filter(filter => filter.sig !== 'inc');
  const incFilters = filters.filter(filter => filter.sig === 'inc');
  if (!incFilters.length) {
    const incFilter = {
      sig: "inc",
      type: "mask",
      filter: datasets.map(dsid => ({
        text: dsid,
        type: 'dataset',
      }))
    }
    return [...filters, incFilter];
  } else {
    const notDatasetFilters = incFilters[0].filter?.filter(item => item.type !== 'dataset') || [];
    const datasetFilters = datasets.map(dsid => ({
      text: dsid,
      type: 'dataset',
    }))
    const updatedIncFilter = {
      ...incFilters[0],
      filter: [...notDatasetFilters, ...datasetFilters],
    }
    return [...excFilters, ...incFilters.slice(1), updatedIncFilter];
  }
}

export function addDatasetsToFilters(filters, datasets) {
  filters ??= [];
  const excFilters = filters.filter(filter => filter.sig !== 'inc');
  const incFilters = filters.filter(filter => filter.sig === 'inc');
  if (!incFilters.length) {
    const incFilter = {
      sig: "inc",
      type: "mask",
      filter: datasets.map(dsid => ({
        text: dsid,
        type: 'dataset'
      }))
    }
    return [...filters, incFilter];
  } else {
    const notDatasetFilters = incFilters[0].filter?.filter(item => item.type !== 'dataset') || [];
    const datasetFilters = incFilters[0].filter?.filter(item => item.type === 'dataset' && !datasets.includes(item.text)) || [];
    const newFilters = datasets.map(dsid => ({
      text: dsid,
      type: 'dataset'
    }))
    const updatedIncFilter = {
      ...incFilters[0],
      filter: [...notDatasetFilters, ...datasetFilters, ...newFilters],
    }
    return [...excFilters, ...incFilters.slice(1), updatedIncFilter];
  }
}

export function getDatasetsFromFilters(filters) {
  filters ??= [];
  const incFilter = filters.find(filter => filter.sig === 'inc');
  return incFilter && incFilter.filter ?
    incFilter.filter
      .filter(item => item.type === 'dataset')
      .map(item => item.text)
    : [];
}
