import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { selectNotice, removeNotice } from '../../app/appSlice';

export default function Notice () {
  const notice = useSelector(selectNotice);
  const dispatch = useDispatch();

  const open = Boolean(notice);

  if (!open) return null;

  const onClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(removeNotice());
  };

  return (
    <Snackbar
      autoHideDuration={notice.duration}
      onClose={onClose}
      open
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={notice.type}
        onClose={onClose}
      >
        {notice.text}
      </Alert>
    </Snackbar>
  );
}
