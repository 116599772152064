import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  server: process.env.REACT_APP_SERVER,
  hasAuth: !!sessionStorage.getItem('authTicket'),
  companies: [{ name: 'SYSTEM', id: '00000000-0000-0000-0000-000000000000' }],
  company: { name: 'SYSTEM', id: '00000000-0000-0000-0000-000000000000' },
  group: {},
  lang: localStorage.getItem('lang') || 'rus',
  notice: null,
  domainLock: true,
};

export const fetchCompanies = () => ({ type: 'app/fetchCompanies' });
export const fetchAppData = () => ({ type: 'app/fetchData' });

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    changeServer: (state, action) => {
      state.server = action.payload;
    },
    changeCompany: (state, action) => {
      const company = state.companies.find((company) => company.id === action.payload);
      state.company = { ...company };
    },
    setGroup: (state, { payload }) => {
      state.group = payload.groups.items[0] || {};
    },
    changeLang: (state, action) => {
      state.lang = action.payload;
      localStorage.setItem('lang', action.payload);
    },
    setCompanies: (state, action) => {
      if (!action.payload.companies) return state;
      const company = action.payload.companies.company;
      // const list = companies.map(company => ({ id: company.compid, name: company.name }));
      // state.companies = [state.companies[0], ...list];

      // const host = window.location.hostname;
      // const company = companies.find(company => company.domain === host);
      // if (company) {
      state.company = { id: company.compid, name: company.name };
      // } else {
      //   state.domainLock = false;
      // }
    },
    removeNotice: (state, action) => {
      state.notice = null;
    },
    setNotice: (state, action) => {
      const notice = {
        type: 'info',
        duration: 5000,
        ...action.payload,
      };
      state.notice = notice;
    },
    setAuth: (state, { payload }) => {
      state.hasAuth = payload;
    },
  },
});

export const { changeCompany, changeLang, removeNotice, setNotice, setAuth } = appSlice.actions;

export const selectCompany = (state) => state.app.company;
export const selectCompanies = (state) => state.app.companies;
export const selectGroup = (state) => state.app.group;
export const selectLang = (state) => state.app.lang;
export const selectServer = (state) => state.app.server;
export const selectNotice = (state) => state.app.notice;
export const selectHasAuth = (state) => state.app.hasAuth;
export const selectDomainLock = (state) => state.app.domainLock;

export default appSlice.reducer;
