import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableUI from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import styles from './Table.module.css';

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string,
      align: PropTypes.string,
      minWidth: PropTypes.number,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  onSelect: PropTypes.func,
  selected: PropTypes.string,
  checkbox: PropTypes.bool,
  onCheck: PropTypes.func,
  checked: PropTypes.arrayOf(PropTypes.string),
  onContextMenu: PropTypes.func,
  extraClass: PropTypes.string,
  extraCondition: PropTypes.func,
};

Table.defaultProps = {
  size: 'small',
  selected: null,
  onSelect: () => {},
  onCheck: () => {},
  extraClass: '',
  checkbox: false,
};

const visuallyHidden = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: 1,
  margin: -1,
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  top: 20,
  width: 1,
};

export default function Table({
  columns,
  rows,
  rowId,
  size,
  onSelect,
  selected,
  checkbox,
  onCheck,
  checked,
  onContextMenu,
  extraCondition,
  extraClass,
}) {
  const { t } = useTranslation();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const sortedRows = stableSort(rows, getComparator(order, orderBy));

  const contextMenuHandler = (row) => (e) => {
    if (!onContextMenu) return;
    onContextMenu(e, row);
    onSelect(row);
  };

  const handleCheckAllClick = (event) => {
    if (event.target.checked) {
      onCheck(rows.map((row) => row[rowId]));
    } else {
      onCheck([]);
    }
  };

  const handleCheckClick = (id) => (event) => {
    if (event.target.checked) {
      onCheck([...checked, id]);
    } else {
      onCheck(checked.filter((item) => item !== id));
    }
  };

  const createSortHandler = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const checkedCount = checked && checked.length;
  const rowCount = rows.length;

  return (
    <TableContainer className="height100">
      <TableUI stickyHeader size={size}>
        <TableHead>
          <TableRow>
            {checkbox && (
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={checkedCount > 0 && checkedCount < rowCount}
                  checked={rowCount > 0 && checkedCount === rowCount}
                  onChange={handleCheckAllClick}
                />
              </TableCell>
            )}
            {columns.map((column) => {
              const key = column.key ?? column.id;
              return column.sortable ? (
                <TableCell key={key} align={column.align} sortDirection={orderBy === key ? order : false}>
                  <TableSortLabel
                    active={orderBy === key}
                    direction={orderBy === key ? order : 'asc'}
                    onClick={createSortHandler(key)}
                  >
                    <b>{column.label}</b>
                    {orderBy === key ? (
                      <span style={visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell
                  key={column.key ?? column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                >
                  <b>{column.label}</b>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => {
            let extraCls = extraClass;
            if (extraCondition) {
              extraCls = extraCondition(row) ? extraClass : '';
            }
            const isChecked = checked && checked.includes(row[rowId]);
            return (
              <TableRow
                key={row[rowId]}
                hover
                className={styles[extraCls]}
                onClick={() => onSelect(row)}
                onContextMenu={contextMenuHandler(row)}
                selected={row[rowId] === selected}
              >
                {checkbox && (
                  <TableCell padding="checkbox">
                    <Checkbox checked={isChecked} onChange={handleCheckClick(row[rowId])} />
                  </TableCell>
                )}
                {columns.map((column) => {
                  const value = row[column.id];
                  const key = column.key ?? column.id;
                  return (
                    <TableCell key={key} align={column.align}>
                      {column.template
                        ? column.template(value, row)
                        : column.format
                        ? column.format(value, row)
                        : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          {!rows.length && (
            <TableRow>
              <TableCell colSpan={columns.length + (checkbox ? 1 : 0)}>
                <p className="text-center">{t('notice:noData')}</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableUI>
    </TableContainer>
  );
}
