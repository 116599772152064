import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import Table from '../../../common/Table/Table';
import Placeholder from '../../../features/placeholder/Placeholder';
import { selectQueues, selectTargets } from '../userSlice';
import QueueSwitch from './QueueSwitch';
import styles from '../Profile.module.css';

export default function Subscriptions({ onSelect, selected, onAdd }) {
  const { t } = useTranslation();

  const queues = useSelector(selectQueues);
  const targets = useSelector(selectTargets);

  const getTargetNameById = id => {
    const target = targets.find(target => target.tarid === id) || {}
    return target.name || '-';
  }

  const columns = [
    { id: 'caption', label: t('common:name'), minWidth: 170 },
    { id: 'tarid', label: t('profile:target'), format: (value) => getTargetNameById(value) },
    {
      id: 'archived', label: t('profile:status'),
      format: (value, row) => <QueueSwitch qid={row.qid} />
    },
  ];

  useEffect(() => {
    if (!selected) return
    const queue = queues.find(target => target.qid === selected.qid)
    onSelect(queue)
  }, [queues])

  return (
    <div className="height100">
      <h3 className={styles.title}>
        <Button
          className={`${styles.headerButton} button-success`}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={onAdd}>
          {t('button:add')}
        </Button>
        {t('profile:subscriptions')}
      </h3>
      {queues && !!queues.length &&
        <Table
          columns={columns}
          rows={queues}
          rowId="qid"
          onSelect={onSelect}
          selected={selected && selected.qid}
          stickyHeader
        />
      }
      {queues && !queues.length &&
        <Placeholder mini type="nodata" text={t('notice:noQueues')} />
      }
    </div>
  );
}
