import { call, put, takeEvery, all } from 'redux-saga/effects';
import dataAPI from '../../api/dataAPI';
import casAPI from '../../api/casAPI';

function* fetchInfo(action) {
  try {
    const user = yield call(casAPI.getUser);
    yield put({ type: 'user/setInfo', payload: user, action });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchData(action) {
  const data = {
    payload: action.payload.session.user.usid,
    compid: action.payload.session.user.compid,
  };
  try {
    yield all([
      fetchGroup(),
      fetchTargets(data),
      fetchRequests({ payload: action.payload.session.user }),
      // fetchQueues(data),
      fetchDatasets(data),
      fetchAccessableMetadata(data),
    ]);
    yield put({ type: 'user/parseSubscriptionsFromQueues' });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchGroup() {
  try {
    const response = yield call(casAPI.findGroupByName, 'Administrators');
    yield put({ type: 'app/setGroup', payload: response });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchTargets(action) {
  try {
    const response = yield call(dataAPI.fetchTargets, { uid: action.payload, flag: 'all', order: 'created' });
    yield put({ type: 'user/setTargets', payload: response });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchRequests({ payload }) {
  try {
    const response = yield call(casAPI.fetchSignals, {
      senders: [payload.usid],
      compid: [payload.compid],
      kinds: ['ACCESS'],
      flags: ['all'],
    });
    yield put({ type: 'user/setRequests', payload: response.requests });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* removeRequest({ payload }) {
  try {
    if (Array.isArray(payload.request)) {
      yield all(payload.request.map((request) => call(removeSingleRequest, request)));
    } else {
      yield call(removeSingleRequest, payload.request);
    }
    if (payload.cb) {
      yield payload.cb();
    }
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* removeSingleRequest(request) {
  try {
    if (!request.archived) {
      yield call(casAPI.archiveSignal, request.reqid);
    }
    yield call(casAPI.removeSignal, request.reqid);
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchQueues(action) {
  try {
    const response = yield call(dataAPI.fetchQueues, { uid: [action.payload], order: ['created'], flags: ['filters'] });
    yield put({ type: 'user/setQueues', payload: response });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchDatasets(action) {
  try {
    const response = yield call(dataAPI.fetchDatasets, { compid: action.compid });
    yield put({ type: 'user/setDatasets', payload: response.datasets.items });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* fetchAccessableMetadata({ payload: usid }) {
  try {
    const { user } = yield call(casAPI.fetchUserDatasets, usid);
    const userDatasets = new Set([...(user['group.body']?.datasets || []), ...(user.body?.datasets || [])]);
    yield put({ type: 'user/setUserDatasets', payload: userDatasets });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* updateSubscriptions(action) {
  try {
    yield fetchQueues(action);
    yield put({ type: 'user/parseSubscriptionsFromQueues' });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* addSubscription({ payload }) {
  const queue = { ...payload.queue };
  if (!queue.filters) {
    queue.filters = [
      {
        sig: 'inc',
        type: 'mask',
        filter: [
          {
            field: 'urn',
            text: payload.metadata,
            type: 'mask',
            doubles: true,
          },
        ],
      },
    ];
  } else {
    let incFilters = queue.filters.filter((filter) => filter.sig === 'inc');
    let excFilters = queue.filters.filter((filter) => filter.sig !== 'inc');
    if (incFilters.length) {
      const filterBase = incFilters[0].filter || [];
      const newFilter = [
        ...filterBase,
        {
          field: 'urn',
          text: payload.metadata,
          type: 'mask',
          doubles: true,
        },
      ];

      let newIncFilters = [
        {
          ...incFilters[0],
          filter: newFilter,
        },
      ];

      if (incFilters.length > 1) {
        newIncFilters = [...newIncFilters, ...incFilters.splice(0, 1)];
      }
      queue.filters = [...newIncFilters, ...excFilters];
    } else {
      queue.filters = [
        ...queue.filters,
        {
          sig: 'inc',
          type: 'mask',
          filter: [
            {
              field: 'urn',
              text: payload.metadata,
              type: 'mask',
              doubles: true,
            },
          ],
        },
      ];
    }
  }

  try {
    yield call(dataAPI.updateQueue, queue.qid, { filters: queue.filters });
    yield put({ type: 'user/subscribe', payload: { queue, metadata: payload.metadata } });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

function* removeSubscription({ payload }) {
  const queue = { ...payload.queue };
  if (!queue.filters) queue.filters = [];
  let incFilters = queue.filters.filter((filter) => filter.sig === 'inc').map((f) => ({ ...f }));
  incFilters.forEach((incf) => {
    incf.filter = incf.filter.filter((el) => el.text !== payload.metadata);
    if (payload.datasets && payload.datasets.length) {
      incf.filter = incf.filter.filter((el) => !payload.datasets.includes(el.text));
    }
  });
  queue.filters = [...queue.filters.filter((filter) => filter.sig !== 'inc'), ...incFilters];
  try {
    yield call(dataAPI.updateQueue, queue.qid, { filters: queue.filters });
    yield put({ type: 'user/unsubscribe', payload: { queue, metadata: payload.metadata } });
  } catch (e) {
    yield put({ type: 'app/setNotice', payload: { type: 'error', text: String(e) } });
  }
}

const userSagas = [
  takeEvery('user/getUserByTicket', fetchInfo),
  takeEvery('user/setInfo', fetchData),
  takeEvery('user/fetchTargets', fetchTargets),
  takeEvery('user/fetchRequests', fetchRequests),
  takeEvery('user/removeRequest', removeRequest),
  takeEvery('user/fetchQueues', fetchQueues),
  takeEvery('user/addSubscription', addSubscription),
  takeEvery('user/removeSubscription', removeSubscription),
  takeEvery('user/updateSubscriptions', updateSubscriptions),
];

export default userSagas;
