import Scrollbar from "../Scrollbar/Scrollbar";
import styles from './VerticalTable.module.scss';

export default function VerticalTable({ data = [] }) {
  return (
    <div className={styles.root}>
      <Scrollbar>
        <div className={styles.table}>
          {
            data.map((item) => (
              Object.entries(item).map((([key, value], i) => (
                <div key={i} className={styles.row}>
                  <div className={styles.cell}>{key}</div>
                  <div className={styles.cell}>{value}</div>
                </div>
              )))
            ))
          }
        </div>
      </Scrollbar>
    </div>
  );
}
