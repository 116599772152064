import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { formatISO } from 'date-fns';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import GetAppIcon from '@material-ui/icons/GetApp';

import { selectCompany } from '../../../../app/appSlice';
import { downloadFile } from '../../../../utils';

import geoAPI from '../../../../api/geoAPI';
import dataAPI from '../../../../api/dataAPI';

import styles from './DownloadMetadata.module.css';

const createJob = (source, id, features = {}, dateFrom, dateTo) => {
  const from = dateFrom ? formatISO(dateFrom) : null;
  const to = dateTo ? formatISO(dateTo) : null;
  return {
    name: 'load esimo, send to core',
    tasks: [
      {
        type: 'RESOURCES_CLIENT_DOWN',
        body: {
          settings: {
            source: source,
            resources: [
              {
                id: id,
                time: {
                  from: from,
                  to: to,
                },
                ...features,
              },
            ],
          },
        },
      },
      {
        type: 'DATASPACE_UPLOADER',
        body: {
          settings: {
            ttl: 86400,
          },
        },
      },
    ],
  };
};

const getRecordIdsFromExecutor = (response) => {
  const worker = response.workers.find((worker) => worker.type === 'DATASPACE_UPLOADER');
  return worker ? worker.result.jrids : [];
};

export default function DownloadMetadata({ open, data, onClose }) {
  const { t } = useTranslation();
  const company = useSelector(selectCompany);
  const [logs, setLogs] = useState('');
  const [features, setFeatures] = useState({});
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [params, setParams] = useState([]);
  const [points, setPoints] = useState([]);
  const [executor, setExecutor] = useState(null);
  const [recordsList, setRecordsList] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [recordId, setRecordId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const timerId = useRef();

  const fetchFeatures = async () => {
    if (!data) return;
    setLogs((log) => log + '\nЗагрузка особенностей продукции...');
    try {
      const { handle } = await geoAPI.openFileForRead(data.id);
      const { features } = await geoAPI.readFileFeatures(handle);
      const { sources } = await dataAPI.fetchFeaturePoints(data.id);
      const points = sources.items.reduce((acc, item) => {
        acc.add((item.metadata?.binding?.find((el) => el.urn === data.id) || {})?.index);
        return acc;
      }, new Set());
      features.points = Array.from(points).filter(Boolean);
      setFeatures(features);
      setLogs((log) => log + '\nОсобенности продукции загружены');
    } catch (e) {
      setLogs((log) => log + '\nОшибка загрузки особенностей продукции: ' + e.message);
    }
  };

  const handleDownloadData = async () => {
    if (!data) return;
    setIsLoading(true);
    const options = {
      ...features,
      params,
      points,
    };
    const job = createJob(data.src, data.id, options, dateFrom, dateTo);
    try {
      const { response } = await geoAPI.createExecutor(company.id, job);
      setExecutor(response.exid);
      setLogs((log) => log + '\nСоздан новый исполнитель\nИдет выполнение задачи...');
    } catch (e) {
      setIsLoading(false);
      setLogs((log) => log + '\nОшибка создания исполнителя: ' + e.message);
    }
  };

  const startExecutorPolling = (id) => {
    timerId.current = setInterval(async () => {
      try {
        const { response } = await geoAPI.fetchExecutor(id);
        if (response.status === 'finished') {
          setLogs((log) => log + '\nИсполнитель успешно завершил задачу');
          setExecutor(null);
          const ids = getRecordIdsFromExecutor(response);
          setRecordsList(ids);
          !ids.length && setLogs((log) => log + '\nЗаписей не найдено!');
        }
        if (response.status === 'errored') {
          setLogs((log) => log + '\nВыполнение задачи завершилось ошибкой!');
          setExecutor(null);
          setIsLoading(false);
        }
      } catch (e) {
        setLogs((log) => log + '\nВозникла ошибка при опросе исполнителя!');
        setExecutor(null);
        setIsLoading(false);
      }
    }, 1 * 1000);
  };

  const stopExecutorPolling = () => {
    clearInterval(timerId.current);
  };

  const fetchFilesList = async (jrids) => {
    const response = await dataAPI.fetchRecordsFromStorage(jrids);
    setFilesList(response.jrecs.items);
    setLogs((log) => log + '\nНайдены данные для загрузки');
  };

  const fetchFileData = async (jrid) => {
    try {
      const response = await dataAPI.fetchRecord(jrid);
      const link = response.file_link?.link_id;
      setLogs((log) => log + '\nЗагрузка файла...');
      const { file_link, body } = await dataAPI.downloadFile(link, true);
      downloadFile(file_link.name, `data:${file_link.type};base64,` + body, 'raw');
      setLogs((log) => log + '\nЗагрузка завершена успешно!');
    } catch (e) {
      setLogs((log) => log + '\nВозникла ошибка при загрузке файла!');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (open) {
      fetchFeatures();
    } else {
      setFeatures([]);
      setExecutor(null);
      setRecordsList([]);
      setFilesList([]);
      setRecordId(null);
      setLogs('');
      setIsLoading(false);
      setDateFrom(null);
      setDateTo(null);
      setParams([]);
      setPoints([]);
    }
  }, [open]);

  useEffect(() => {
    if (!executor) return;
    geoAPI.startExecutor(executor);
    startExecutorPolling(executor);

    return stopExecutorPolling;
  }, [executor]);

  useEffect(() => {
    if (!recordsList.length) {
      return;
    }
    setLogs((log) => log + '\nПолучение информации о записях...');
    fetchFilesList(recordsList);
  }, [recordsList]);

  useEffect(() => {
    if (!recordId) return;
    fetchFileData(recordId);
  }, [recordId]);

  const isDownloadButtonDisabled = isLoading || !features;

  return (
    <Dialog open={open} onClose={onClose} className="nowidth-limit">
      <DialogTitle>{t('searchPage:metadataDownload')}</DialogTitle>
      <DialogContent>
        <div className={styles.content}>
          <div className={styles.leftBlock}>
            <div className={styles.subtitle}>Временной интервал:</div>
            <div className={styles.dateFields}>
              <TextField
                label="От"
                type="date"
                onChange={({ target }) => setDateFrom(target.valueAsDate)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="До"
                type="date"
                onChange={({ target }) => setDateTo(target.valueAsDate)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className={styles.subtitle}>Настройки:</div>
            <br />
            <InputLabel id="params-label">Params</InputLabel>
            <Select
              className={styles.select}
              labelId="params-label"
              multiple
              value={params}
              onChange={(event) => setParams(event.target.value)}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
            >
              {features.params?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={params.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <InputLabel id="points-label">Points</InputLabel>
            <Select
              className={styles.select}
              labelId="points-label"
              multiple
              value={points}
              onChange={(event) => setPoints(event.target.value)}
              input={<Input />}
              renderValue={(selected) => selected.join(', ')}
            >
              {features.points?.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={points.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
            <br />
            <textarea className={styles.textArea} readOnly value={logs} />
          </div>
          <div className={styles.rightBlock}>
            <div className={styles.subtitle}>Результаты:</div>
            <div className={styles.list}>
              {filesList.map((item) => (
                <div key={item.jrid} className={styles.listItem}>
                  <span>{item.name}</span>
                  <Button size="small" variant="contained" color="primary" onClick={() => setRecordId(item.jrid)}>
                    <GetAppIcon />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" size="small">
          {t('button:close')}
        </Button>
        <Button
          variant="contained"
          disabled={isDownloadButtonDisabled}
          onClick={handleDownloadData}
          color="primary"
          size="small"
        >
          {t('button:request')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
