import React, { useState, useEffect, useRef } from 'react';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
// import debounce from 'lodash.debounce';
// import { useDebouncedEffect } from '../../utils';
import Highlighter from 'react-highlight-words';
import styles from './AutocompleteSelect.module.css'

const Menu = (props) => {
  return (
    <components.Menu {...props}>
      <div id="input-options-menu">
        {props.children}
      </div>
    </components.Menu>
  );
};

const Option = (props) => {
  return (
    <components.Option {...props} isSelected={false} />
  );
};

const DropdownIndicator = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      {null}
    </components.DropdownIndicator>
  );
};

export default function AutocompleteSelect(props) {
  const [inputValue, setInputValue] = useState('');
  const enterButtonBlock = useRef(true);
  const [selectedOption, setSelectedOption] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const selectStyles = {
    singleValue: (styles) => ({
      ...styles,
      opacity: props.isInput ? 0 : 1,
    }),
  }

  const handleInputChange = (value, { action }) => {
    if (props.isInput &&
      (action === "set-value" ||
        action === "input-blur" ||
        action === "menu-close")
    ) {
      return value;
    }
    setInputValue(value);
    props.isInput && props.onChange(value);
    return value;
  }

  const handleChange = (option) => {
    if (props.isInput) {
      handleInputChange(option.value, { action: 'select-option' })
      props.isInput && props.onComplete(option.value);
    } else {
      setSelectedOption(option);
      props.onChange(option);
    }
  }

  useEffect(() => {
    if (props.value) {
      setSelectedOption(props.value);
    }
  }, [props.value]);

  // const fetchSuggestions = async () => {
  //   if (!inputValue) return;
  //   const [text, suggestions] = await geoAPI.fetchSuggestions(inputValue);
  //   setOptions(suggestions.map( item => ({value: item, label: item})));
  // }

  // useDebouncedEffect(fetchSuggestions, [inputValue], 1000);

  const cls = `${styles.select} ${props.className ? props.className : ''}`;

  // useEffect(() => {
  //   const trackArrowsPush = ({ keyCode }) => {
  //     console.log('trackarrows')
  //     if (keyCode === 38 || keyCode === 40) {
  //       enterButtonBlock.current = false;
  //     }
  //   }
  //   const trackMouseDown = () => {
  //     console.log('trackmousedown')
  //     enterButtonBlock.current = false;
  //   }
  //   let menu;
  //   if (menuIsOpen) {
  //     menu = document.getElementById('input-options-menu');
  //     window.addEventListener('keydown', trackArrowsPush);
  //     menu && menu.addEventListener('mousedown', trackMouseDown);
  //   } else {
  //     enterButtonBlock.current = true;
  //   }
  //   return () => {
  //     window.removeEventListener('keydown', trackArrowsPush);
  //     menu && menu.removeEventListener('mousedown', trackMouseDown);
  //   }
  // }, [menuIsOpen]);


  return (
    <AsyncSelect
      placeholder=""
      styles={selectStyles}
      cacheOptions
      onSelectResetsInput={false}
      openMenuOnFocus={true}
      openMenuOnClick={false}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
      formatOptionLabel={props.isInput ? formatOptionLabel : null}
      {...props}
      className={cls}
      value={selectedOption}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      components={{ Menu, Option, DropdownIndicator }}
    />
  )
}

function formatOptionLabel({ label }, { inputValue }) {
  return (
    <Highlighter
      searchWords={[inputValue]}
      textToHighlight={label}
      highlightTag="b"
    />
  );
}
