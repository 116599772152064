import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './app/App';
import { store } from './app/store';
import { injectStoreToAPI } from './api/dataAPI';
import { injectStoreToAPI as injectStoreToGeoAPI } from './api/geoAPI';
import { injectStoreToAPI as injectStoreToCasAPI } from './api/casAPI';
import { injectStoreToAPI as injectStoreToManAPI } from './api/manAPI';
import reportWebVitals from './reportWebVitals';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import './lang';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

let url = window.location.href;
if (/localhost|heroku/g.test(url)) {
  sessionStorage.setItem('authTicket', 'ST-test');
}

injectStoreToAPI(store);
injectStoreToGeoAPI(store);
injectStoreToCasAPI(store);
injectStoreToManAPI(store);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a76d2',
    },
    secondary: {
      main: '#4527a0',
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
